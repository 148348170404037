import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';

// Hooks
import useAuth from 'hooks/useAuth';
import useCurrency from 'hooks/useCurrency';
import useFollowing from 'hooks/useFollowing';
import useResponsive from 'hooks/useResponsive';
import useSignupPopup from 'hooks/useSignupPopup';
import useBrandsVisited from 'hooks/useBrandsVisited';

// Utils & Consts
import { GTMFollowBrand } from 'utils/gtm';
import { noOp, setPriceInDesiredCurrency } from 'utils/functions';

// Components
import Link from 'components/Link/Link';
import BrandCardSkeleton from './BrandCardSkeleton';
import BrandCardLayout from './layouts/BrandCardLayout';

const BUTTON_TEXT_VALUES = Object.freeze({
  FOLLOW: 'Follow',
  UNFOLLOW: 'Unfollow',
  FOLLOWING: 'Following',
});

const brandCardDefaultPropSx = {};

const BrandCard = ({
  sx = brandCardDefaultPropSx,
  brand,
  isLoading = false,
  handleItemIdChange = noOp,
  algoliaQueryID = '',
  index = -1,
}) => {
  const router = useRouter();
  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');
  const { isAuthenticated, userId } = useAuth();
  const { currency, exchangeRates } = useCurrency();
  const { handleShowSignupPopup } = useSignupPopup();
  const { following, follow, unfollow } = useFollowing();
  const { setBrandsVisited, showSignupPopup } = useBrandsVisited();

  const [isFollowing, setIsFollowing] = useState(false);
  const [buttonVariant, setButtonVariant] = useState('contained');
  const [selectedMinOrderValue, setSelectedMinOrderValue] = useState(0);
  const [followBtnText, setFollowBtnText] = useState(BUTTON_TEXT_VALUES.FOLLOW);
  const [currencySymbol, setCurrencySymbol] = useState(currency?.symbol || '£');

  // const { SEARCH_RESULTS_ALGOLIA: isAlgoliaEnabled } = useFeatureFlags();

  // Send Algolia Insights events only from search results page
  // const shouldSendAlgoliaInsights = isAlgoliaEnabled;
  // const isAfterSearch = !!algoliaQueryID;
  // const algoliaBrandClickEventParams = [
  //   'brand',
  //   {
  //     objectID: brand?.id,
  //     ...(algoliaQueryID && { queryID: algoliaQueryID }),
  //     position: index + 1,
  //   },
  //   isAfterSearch,
  // ];

  // Following status
  useEffect(() => {
    setIsFollowing(following?.includes(brand?.WP_id));
  }, [following, brand?.WP_id]);

  useEffect(() => {
    if (isFollowing) {
      setFollowBtnText(BUTTON_TEXT_VALUES.FOLLOWING);
      setButtonVariant('outlined');
    } else {
      setFollowBtnText(BUTTON_TEXT_VALUES.FOLLOW);
      setButtonVariant('contained');
    }
  }, [isFollowing]);

  // Currency handling
  useEffect(() => {
    if (currency?.symbol) {
      setCurrencySymbol(currency.symbol);
    }
    if (currency?.name) {
      // min order
      const minOrderInSelectedCurrency = brand?.store?.wholesalerStoreMinOrder
        ?.filter((x) => x.wholesalerStoreMinOrderAmount > 0 && x.wholesalerStoreMinOrderCurrency === currency.name)
        .pop();

      if (minOrderInSelectedCurrency) {
        setSelectedMinOrderValue(Number(minOrderInSelectedCurrency.wholesalerStoreMinOrderAmount) / 100);
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            brand?.store?.wholesalerStoreMinOrder,
            'wholesalerStoreMinOrderAmount',
            'wholesalerStoreMinOrderCurrency',
            currency.name,
            setSelectedMinOrderValue,
            exchangeRates
          );
        })();
      }
    }
  }, [brand?.store?.wholesalerStoreMinOrder, currency, exchangeRates]);

  // Event handlers
  const handleFollowButtonClick = async () => {
    if (!isAuthenticated) {
      handleShowSignupPopup();
      return;
    }

    if (isFollowing) {
      await unfollow(brand?.WP_id);
    } else {
      await follow(brand?.WP_id);
      GTMFollowBrand(
        brand?.attributes?.wholesalerName,
        brand?.WP_id,
        brand?.store?.wholesalerStoreCountry,
        brand?.store?.wholesalerShipsInDays,
        userId
      );
    }
  };

  const handleFollowBtnOnMouseEnter = () => {
    setFollowBtnText(isFollowing ? BUTTON_TEXT_VALUES.UNFOLLOW : BUTTON_TEXT_VALUES.FOLLOW);
  };

  const handleFollowBtnOnMouseLeave = () => {
    setFollowBtnText(isFollowing ? BUTTON_TEXT_VALUES.FOLLOWING : BUTTON_TEXT_VALUES.FOLLOW);
  };

  const brandCardLink = algoliaQueryID ? `/store/${brand?.slug}?query-id=${algoliaQueryID}` : `/store/${brand?.slug}`;

  const handleRedirectToBrand = useCallback(() => {
    setBrandsVisited(brand?.id);
    if (showSignupPopup && !isAuthenticated) {
      handleShowSignupPopup();
      return;
    }

    // Disabling algolia brand click event temporarily
    // if (shouldSendAlgoliaInsights) {
    //   pushAlgoliaClickEvent(...algoliaBrandClickEventParams);
    // }
    if (!isDesktop) {
      handleItemIdChange(brand?.id);
      router.push(brandCardLink);
    }
  }, [brand?.id, brand?.slug, isAuthenticated, showSignupPopup, algoliaQueryID, index, isDesktop]);

  const getConditionalWrapper = useCallback(
    (children) => <Box onClick={handleShowSignupPopup}>{children}</Box>,
    [handleShowSignupPopup]
  );

  const getDefaultWrapper = useCallback(
    (children) => (
      <Link
        passHref
        underline="none"
        href={brandCardLink}
        sx={{ width: '100%' }}
        aria-label={brand?.slug}
        data-testid="brand-detail-link"
        onClick={handleRedirectToBrand}
        {...(isDesktop ? { target: '_blank' } : {})}
      >
        {children}
      </Link>
    ),
    [brand?.slug, handleRedirectToBrand, brandCardLink, isDesktop]
  );

  if (isLoading) {
    return <BrandCardSkeleton />;
  }

  return (
    <BrandCardLayout
      sx={sx}
      brand={brand}
      currencySymbol={currencySymbol}
      selectedMinOrderValue={selectedMinOrderValue}
      isFollowing={isFollowing}
      followBtnText={followBtnText}
      buttonVariant={buttonVariant}
      handleFollowButtonClick={handleFollowButtonClick}
      handleFollowBtnOnMouseEnter={handleFollowBtnOnMouseEnter}
      handleFollowBtnOnMouseLeave={handleFollowBtnOnMouseLeave}
      isMobile={isMobile}
      conditionalWrapConfig={{
        condition: !isAuthenticated && showSignupPopup,
        conditionalWrapper: getConditionalWrapper,
        defaultWrapper: getDefaultWrapper,
      }}
    />
  );
};

BrandCard.propTypes = {
  sx: PropTypes.shape({}),
  brand: PropTypes.shape({
    id: PropTypes.string.isRequired,
    WP_id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      wholesalerName: PropTypes.string,
      wholesalerDescription: PropTypes.string,
    }).isRequired,
    flairs: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    store: PropTypes.shape({
      wholesalerStoreCountry: PropTypes.string,
      wholesalerShipsInDays: PropTypes.string,
      wholesalerStoreLogo: PropTypes.shape({
        wholesalerStoreLogoImage: PropTypes.string,
        wholesalerStoreLogoBasePath: PropTypes.string,
      }),
      wholesalerStoreMinOrder: PropTypes.arrayOf(
        PropTypes.shape({
          wholesalerStoreMinOrderAmount: PropTypes.number,
          wholesalerStoreMinOrderCurrency: PropTypes.string,
        })
      ),
    }).isRequired,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        productThumbnailName: PropTypes.string,
        productThumbnailBaseUrl: PropTypes.string,
      })
    ),
  }).isRequired,
  isLoading: PropTypes.bool,
  handleItemIdChange: PropTypes.func,
  algoliaQueryID: PropTypes.string,
  index: PropTypes.number,
};

export default BrandCard;
