export const validateStockAmount = ({ productQuantity, product, cartItemId, hasVariations, handleStockError }) => {
  let inventoryData = product?.productInventory;
  let productId = product?.id;
  let productMinQuantity = product?.productMinQuantity;

  if (hasVariations) {
    const data = product?.variations?.filter((p) => p?.id === cartItemId)?.[0];
    productId = data?.id;
    inventoryData = data?.inventory;
    productMinQuantity = data?.productMinQuantity;
  }

  const productStock = inventoryData?.productStock;
  const productInventoryTracked = inventoryData?.productInventoryTracked;
  const productInventoryAllowBackorders = inventoryData?.productInventoryAllowBackorders;
  const productInventoryStatus = inventoryData?.productInventoryStatus;

  if (productInventoryAllowBackorders !== 'no') {
    return;
  }

  if (productInventoryTracked === 'yes') {
    if (productMinQuantity !== 0 && productStock < productMinQuantity) {
      handleStockError((prev) => {
        return prev.some((p) => p.productId === productId)
          ? prev
          : [
              ...prev,
              {
                productId,
                message: `Out of stock`,
              },
            ];
      });
      return;
    }

    const showWarningAmount = productStock - 1;
    if (productQuantity >= showWarningAmount) {
      handleStockError((prev) => {
        return prev.some((p) => p.productId === productId)
          ? prev
          : [
              ...prev,
              {
                productId,
                message: `Only ${productStock} available`,
              },
            ];
      });
    }
  } else if (productInventoryStatus === 'out_of_stock') {
    handleStockError((prev) => {
      return prev.some((p) => p.productId === productId)
        ? prev
        : [
            ...prev,
            {
              productId,
              message: `Out of stock`,
            },
          ];
    });
  }
};

export const isProductOutOfStock = ({ product }) => {
  const productStock = product?.inventory?.productStock;
  const productMinQuantity = product?.attributes?.productMinQuantity;
  const productInventoryTracked = product?.inventory?.productInventoryTracked;
  const productInventoryAllowBackorders = product?.inventory?.productInventoryAllowBackorders;

  if (!productInventoryAllowBackorders && !productInventoryTracked && !productStock) {
    return false;
  }

  if (productInventoryTracked === 'no' || productInventoryAllowBackorders === 'yes') {
    return false;
  }

  if (productMinQuantity !== 0 && productStock < productMinQuantity) {
    return true;
  }

  return false;
};
