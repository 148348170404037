import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Link from 'components/Link/Link';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import useResponsive from 'hooks/useResponsive';

const ViewShopButton = ({ inCart = PropTypes.bool, href = PropTypes.string, onClick = PropTypes.func }) => {
  const isDesktop = useResponsive('up', 'md');
  const isMobile = useResponsive('down', 'sm');

  return (
    <Button
      href={href}
      passHref
      component={Link}
      variant={inCart ? 'contained' : 'outlined'}
      startIcon={<OpenInNewIcon />}
      sx={{ borderRadius: '8px', alignItems: 'center' }}
      size={isMobile ? 'medium' : 'large'}
      {...(isDesktop ? { target: '_blank' } : {})} // Open in a new tab in Desktop and laptop devices as A/B testing is required
      onClick={onClick} // Close the popover when this button is clicked
    >
      View Shop
    </Button>
  );
};

export default ViewShopButton;

ViewShopButton.propTypes = {
  inCart: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
};
