export const DEFAULT_DIVISOR_ERROR_MESSAGE = 'The divisor (multipleOf) cannot less than or equal zero.';

/**
 * Given a number and a multiple, return the next value that is a multiple of the given number.
 * @param {number} currentNumber The current number
 * @param {number} multipleOf The multiple of values accepted
 * @returns {number} The next multiple of the given number
 *
 * @example
 * nextMultiple(10, 5); // 15
 * nextMultiple(10, 10); // 20
 * nextMultiple(10, 3); // 12
 */
export const nextMultiple = (currentNumber, multipleOf) => {
  if (multipleOf <= 0) throw new Error(DEFAULT_DIVISOR_ERROR_MESSAGE);
  return Math.ceil((currentNumber + 1) / multipleOf) * multipleOf;
};

/**
 * Given a number and a multiple, return the previous value that is a multiple of the given number.
 * @param {number} currentNumber The current number
 * @param {number} multipleOf The multiple of values accepted
 * @returns {number} The previous multiple of the given number
 */
export const previousMultiple = (currentNumber, multipleOf) => {
  if (multipleOf <= 0) throw new Error(DEFAULT_DIVISOR_ERROR_MESSAGE);
  return Math.floor((currentNumber - 1) / multipleOf) * multipleOf;
};

/**
 * Given a number and a multiple, return whether the number is a multiple of the `multipleOf` value.
 * @param {number} number The current number
 * @param {number} multipleOf The multiple of values accepted
 * @returns {boolean} Whether the number is a multiple of the given number
 */
export const isMultiple = (number, multipleOf) => {
  if (multipleOf <= 0) throw new Error(DEFAULT_DIVISOR_ERROR_MESSAGE);
  return number % multipleOf <= 0;
};
