import PropTypes from 'prop-types';

// Material-UI
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// Components
import VirgoFlair from 'components/Flair';
import CustomImage from 'components/CustomImage';
import BabyGlideCarousel from 'components/GlideCarousel/BabyGlideCarousel';
import ConditionalWrap from 'components/ConditionalWrap';

// Utils & Consts
import { RESOLUTION } from 'constants/image-resolution';
import { LIST_ITEM_FALLBACK } from 'constants/images-defaults';
import { transformImageUrl } from 'utils/functions';
import { getImageOrDefault } from 'utils/products';

// styles
import { brandCardStyles } from './BrandCardStyles';

const brandCardLayoutDefaultPropSx = {};

const BrandCardLayout = ({
  sx = brandCardLayoutDefaultPropSx,
  brand,
  currencySymbol,
  conditionalWrapConfig,
  selectedMinOrderValue,
  followBtnText,
  buttonVariant,
  handleFollowButtonClick,
  handleFollowBtnOnMouseEnter,
  handleFollowBtnOnMouseLeave,
  isMobile,
}) => {
  const screenType = isMobile ? 'mobile' : 'web';

  return (
    <Card sx={{ ...brandCardStyles.root[screenType], ...sx }} data-testid="brand-card">
      <Box data-testid="brand-card-carousel" sx={{ ...brandCardStyles.imageContainer[screenType], overflow: 'hidden' }}>
        {brand?.flairs?.length > 0 && <VirgoFlair flair={brand?.flairs?.[0]} sx={brandCardStyles.flair[screenType]} />}

        <BabyGlideCarousel
          config={{
            isMobile,
            swipeable: false,
            isBrandCarousel: true,
            bulletsLength: brand?.products?.length,
          }}
        >
          {brand?.products?.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <CardActionArea data-testid="brand-card-product-images" key={`card-${i}-${item.productThumbnailName}`}>
              <ConditionalWrap {...conditionalWrapConfig}>
                <CustomImage
                  width={isMobile ? 172 : 236}
                  height={isMobile ? 172 : 236}
                  sx={{
                    objectFit: 'cover',
                    maxHeight: `${isMobile ? '172px' : '236px'}`,
                    minHeight: `${isMobile ? '172px' : '236px'}`,
                  }}
                  sizes="100vw"
                  alt={item?.name}
                  loading="lazy"
                  fallbackSrc={LIST_ITEM_FALLBACK}
                  src={
                    item.productThumbnailBaseUrl
                      ? transformImageUrl(
                          item.productThumbnailBaseUrl,
                          item.productThumbnailName,
                          isMobile ? RESOLUTION.CARD.MOBILE : RESOLUTION.CARD.DESKTOP
                        )
                      : getImageOrDefault({
                          base: item.productThumbnailBaseUrl,
                          path: item.productThumbnailName,
                        })
                  }
                />
              </ConditionalWrap>
            </CardActionArea>
          ))}
        </BabyGlideCarousel>
      </Box>
      <Stack sx={brandCardStyles.brandInfo[screenType]}>
        <ConditionalWrap {...conditionalWrapConfig}>
          <CardActionArea>
            <Typography
              variant="h6"
              component="h3"
              align="center"
              sx={brandCardStyles.brandTitle[screenType]}
              className="notranslate"
              data-testid="brand-card-title"
            >
              {brand.attributes?.wholesalerName}
            </Typography>
          </CardActionArea>
        </ConditionalWrap>

        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ ...brandCardStyles.brandDetails[screenType] }}
          data-testid="brand-details"
        >
          {`${brand?.store?.wholesalerStoreCountry}, ${currencySymbol}${selectedMinOrderValue} min`}
        </Typography>

        <Button
          color="primary"
          variant={buttonVariant}
          onClick={handleFollowButtonClick}
          onMouseEnter={handleFollowBtnOnMouseEnter}
          onMouseLeave={handleFollowBtnOnMouseLeave}
          sx={brandCardStyles.followButton[screenType]}
          data-testid="follow-button"
        >
          {followBtnText}
        </Button>
      </Stack>
    </Card>
  );
};

BrandCardLayout.propTypes = {
  sx: PropTypes.shape({}),
  brand: PropTypes.shape({
    id: PropTypes.string,
    WP_id: PropTypes.string,
    slug: PropTypes.string,
    attributes: PropTypes.shape({
      wholesalerName: PropTypes.string,
      wholesalerDescription: PropTypes.string,
    }),
    flairs: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    store: PropTypes.shape({
      wholesalerStoreCountry: PropTypes.string,
    }),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        productThumbnailName: PropTypes.string,
        productThumbnailBaseUrl: PropTypes.string,
      })
    ),
  }).isRequired,
  index: PropTypes.number,
  currencySymbol: PropTypes.string.isRequired,
  conditionalWrapConfig: PropTypes.shape({
    condition: PropTypes.bool,
    conditionalWrapper: PropTypes.func,
    defaultWrapper: PropTypes.func,
  }).isRequired,
  selectedMinOrderValue: PropTypes.number.isRequired,
  followBtnText: PropTypes.string.isRequired,
  buttonVariant: PropTypes.oneOf(['contained', 'outlined']).isRequired,
  handleFollowButtonClick: PropTypes.func.isRequired,
  handleFollowBtnOnMouseEnter: PropTypes.func.isRequired,
  handleFollowBtnOnMouseLeave: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default BrandCardLayout;
