/* eslint-disable no-new-wrappers */
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useDebounce, useLocalStorage } from 'react-use';
import { useState, useEffect, useCallback } from 'react';

// Material-UI
import Box from '@mui/material/Box';

// Hooks
import useAuth from 'hooks/useAuth';
import useCart from 'hooks/useCart';
import useWishlist from 'hooks/useWishlist';
import useCurrency from 'hooks/useCurrency';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useProductsVisited from 'hooks/useProductsVisited';
import useAddToCartPopover from 'hooks/useAddToCartPopover';
import useReferrals from 'hooks/useReferrals';

// Utils & Consts
import {
  noOp,
  getProductSubtotal,
  setPriceInDesiredCurrency,
  getCartItemsWithCommonWholesalerId,
} from 'utils/functions';
import WP_ROUTES from 'constants/wordpress-routes';
import { validateStockAmount } from 'utils/stockValidation';
import { getWholesalerSummary } from '@creoate/cart-lib/discounts';
import {
  GTMProductWishlistEvents,
  GTMProductEvents,
  GTMProductAddToCartEvents,
  pushAlgoliaAddToCartEvent,
  pushAlgoliaClickEvent,
} from 'utils/gtm';

// CMPS
import Link from 'components/Link/Link';
import useSignupPopup from 'hooks/useSignupPopup';
import ProductCardLayout from 'components/Product/ProductCard/layouts/ProductCardLayout';
import ProductCardSkeleton from './ProductCardSkeleton';

const productCardDefaultPropsSx = {};
const productCardDefaultPropsProduct = {
  id: '',
  WP_id: '',
  flair: {
    type: '',
    value: '',
  },
  productSlug: '',
  brandSlug: '',
  productMinQuantity: 0,
  productPrice: [
    {
      productRRP: '',
      productWSP: '',
      productCurrency: '',
    },
  ],
  productDiscount: [
    {
      productDiscountValue: 0,
      productDiscountCurrency: '',
    },
  ],
  productCategories: [],
  productThumbnailUrl: {
    productThumbnailName: '',
    productThumbnailBaseUrl: '',
  },
  productVariationLabels: [''],
  productName: '',
  wholesaler: {
    name: '',
    country: '',
    description: '',
    rating: '',
    id: '',
    wpId: '',
    logo: {
      wholesalerStoreLogoImage: '',
      wholesalerStoreLogoBasePath: '',
    },
    minOrderValue: [
      {
        wholesalerStoreMinOrderAmount: 0,
        wholesalerStoreMinOrderCurrency: '',
      },
    ],
    storeDiscount: [
      {
        wholesalerStoreDiscountPercentage: 0,
        wholesalerStoreDiscountMinOrderAmount: 0,
      },
    ],
  },
  productBulkDiscount: {
    productBulkDiscountPercentage: 0,
    productBulkDiscountMinOrderQuantity: 0,
  },
};

const ProductCard = ({
  sx = productCardDefaultPropsSx,
  product = productCardDefaultPropsProduct,
  loading = false,
  refProductsNewUrl = '',
  handleItemIdChange = noOp,
  index,
  productCardVariant = 'normal',
  algoliaQueryID = '',
}) => {
  const router = useRouter();
  const { isAuthenticated, userId } = useAuth();
  const { currency, exchangeRates } = useCurrency();
  const { handleShowSignupPopup } = useSignupPopup();
  const { setProductsVisited, showSignupPopup } = useProductsVisited();
  const { wishlist, addToWishlist, removeFromWishlist } = useWishlist();
  const { cart, addToCart, removeFromCart, increaseInCart, decreaseInCart } = useCart();
  const [, setRedirectUrl] = useLocalStorage('redirect-url', '');
  const { queryParamsString } = useReferrals();

  const isUnregistered = !isAuthenticated;

  /* -- A2C Popover -- */
  const { popoverProduct, setPopoverProduct } = useAddToCartPopover();
  const [showAddToCartPopover, setShowAddToCartPopover] = useState(false);

  const [hasVariations, setHasVariations] = useState(false);

  // configs
  const { IS_WORDPRESS_CONNECTED, SEARCH_RESULTS_ALGOLIA: isAlgoliaEnabled } = useFeatureFlags();

  // Send Algolia Insights events only from search results page
  const shouldSendAlgoliaInsights = isAlgoliaEnabled;
  const isAfterSearch = !!algoliaQueryID;

  const productIdentifier = IS_WORDPRESS_CONNECTED ? product?.WP_id : product?.id;
  const wholesalerIdentifier = IS_WORDPRESS_CONNECTED ? product?.wholesaler?.wpId : product?.wholesaler?.id;

  // prices
  const [currencySymbol, setCurrencySymbol] = useState(currency?.symbol || '£');

  const [selectedWSP, setSelectedWSP] = useState(0);
  const [selectedRRP, setSelectedRRP] = useState(0);
  const [selectedDiscountPrice, setSelectedDiscountPrice] = useState(0);
  const [selectedMinOrderValue, setSelectedMinOrderValue] = useState(0);

  // wishlist data & handlers
  const [inWishlist, setInWishlist] = useState(false);

  useEffect(() => {
    const productInWishlist = wishlist?.filter((x) => x.productId === product?.WP_id).pop();

    if (productInWishlist) {
      setInWishlist(true);
    } else {
      setInWishlist(false);
    }
  }, [wishlist, product?.WP_id]);

  const handleAddToWishlist = async () => {
    if (isUnregistered) {
      handleShowSignupPopup();
    } else {
      await addToWishlist([{ productId: product.WP_id }]);
    }
    GTMProductWishlistEvents(
      product.productName,
      product.WP_id,
      selectedWSP,
      product.wholesaler?.name,
      `/product/${product.productSlug}`,
      `${product?.productThumbnailUrl?.productThumbnailBaseUrl}/${product?.productThumbnailUrl?.productThumbnailName}`,
      product?.productCategories,
      userId
    );
  };

  const handleRemoveFromWishlist = async () => {
    await removeFromWishlist({ productId: product.WP_id });
  };

  // cart data & handlers
  const [stockError, setStockError] = useState([]);

  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [inApiCall, setInApiCall] = useState(false);
  const [isSelectingQuantity, setIsSelectingQuantity] = useState(false);

  const [inCart, setInCart] = useState(false);
  const [productQuantityInCart, setProductQuantityInCart] = useState(0);
  const [cartItemId, setCartItemId] = useState(null);

  const [addToCartMinQuantity, setAddToCartMinQuantity] = useState(1);
  const [actualQuantity, setActualQuantity] = useState(null);
  const [previousQuantity, setPreviousQuantity] = useState(0);
  const [quantityToReset, setQuantityToReset] = useState(null);
  const [shouldTriggerCartApi, setShouldTriggerCartApi] = useState(false);

  const setProductInCartQuantities = (productInCart) => {
    setQuantityToReset(new Number(productInCart.quantity));
    setPreviousQuantity(productInCart.quantity);
    setActualQuantity(productInCart.quantity);
  };

  const resetCartQuantities = () => {
    setQuantityToReset(null);
    setActualQuantity(null);
    setPreviousQuantity(0);
  };

  const handleRedirectToRegister = async () => {
    const currentPath = router.asPath;
    await setRedirectUrl(currentPath);
    const registerUrl = `${WP_ROUTES.register}?${queryParamsString}`;
    router.push(registerUrl);
  };

  // initial setup
  useEffect(() => {
    const mHasVariations = product?.productVariationLabels && product?.productVariationLabels?.length > 0;
    setHasVariations(mHasVariations);

    const minQuantity = product?.productMinQuantity > 0 ? product.productMinQuantity : 1;
    setAddToCartMinQuantity(minQuantity);

    // only handle cart UI if it doesn't have variations
    if (!mHasVariations) {
      // cart dependencies
      if (cart) {
        const productInCart = cart?.filter((x) => x.productId === productIdentifier).pop();

        if (productInCart) {
          setInCart(true);
          setQuantityToReset(new Number(productInCart.quantity));
          setPreviousQuantity(productInCart.quantity);
          setProductQuantityInCart(productInCart.quantity);
          setActualQuantity(productInCart.quantity);

          if (productInCart.variationId) {
            setCartItemId(productInCart.variationId);
          } else {
            setCartItemId(productInCart.productId);
          }

          validateStockAmount({
            product,
            cartItemId: productInCart.productId,
            hasVariations,
            productQuantity: new Number(productInCart.quantity),
            handleStockError: setStockError,
          });
        } else {
          setInCart(false);
        }
      }
    }

    if (mHasVariations && cart) {
      const total = cart?.reduce((acc, cartProduct) => {
        if (cartProduct?.productId === product?.id) {
          return acc + (cartProduct?.quantity || 0);
        }
        return acc;
      }, 0);
      setProductQuantityInCart(total);
    }
  }, []);

  // react to currency change
  useEffect(() => {
    if (currency?.symbol) {
      setCurrencySymbol(currency.symbol);
    }
    if (currency?.name) {
      // WSP
      const wspInSelectedCurrency = product?.productPrice
        ?.filter((x) => x.productWSP > 0 && x.productCurrency === currency.name)
        .pop();

      if (wspInSelectedCurrency) {
        setSelectedWSP(Number(wspInSelectedCurrency.productWSP) / 100);
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            product?.productPrice,
            'productWSP',
            'productCurrency',
            currency.name,
            setSelectedWSP,
            exchangeRates
          );
        })();
      }

      // RRP
      const rrpInSelectedCurrency = product?.productPrice
        ?.filter((x) => x.productRRP > 0 && x.productCurrency === currency.name)
        .pop();

      if (rrpInSelectedCurrency) {
        setSelectedRRP(Number(rrpInSelectedCurrency.productRRP) / 100);
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            product?.productPrice,
            'productRRP',
            'productCurrency',
            currency.name,
            setSelectedRRP,
            exchangeRates
          );
        })();
      }

      // discounted price
      const discountedPriceInSelectedCurrency = product?.productDiscount
        ?.filter((x) => x.productDiscountValue > 0 && x.productDiscountCurrency === currency.name)
        .pop();

      if (discountedPriceInSelectedCurrency) {
        setSelectedDiscountPrice(Number(discountedPriceInSelectedCurrency.productDiscountValue / 100));
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            product?.productDiscount,
            'productDiscountValue',
            'productDiscountCurrency',
            currency.name,
            setSelectedDiscountPrice,
            exchangeRates
          );
        })();
      }

      // min order
      const minOrderInSelectedCurrency = product?.wholesaler?.minOrderValue
        ?.filter((x) => x.wholesalerStoreMinOrderAmount > 0 && x.wholesalerStoreMinOrderCurrency === currency.name)
        .pop();

      if (minOrderInSelectedCurrency) {
        setSelectedMinOrderValue(Number(minOrderInSelectedCurrency.wholesalerStoreMinOrderAmount) / 100);
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            product?.wholesaler?.minOrderValue,
            'wholesalerStoreMinOrderAmount',
            'wholesalerStoreMinOrderCurrency',
            currency.name,
            setSelectedMinOrderValue,
            exchangeRates
          );
        })();
      }
    }
  }, [currency, product?.productDiscount, product?.productPrice, product?.wholesaler?.minOrderValue, exchangeRates]);

  // react to cart updates
  useEffect(() => {
    if (cart) {
      const productInCart = cart?.filter((x) => x.productId === productIdentifier).pop();

      if (productInCart) {
        setInCart(true);
        setProductInCartQuantities(productInCart);
        if (hasVariations) {
          const total = cart?.reduce((acc, cartProduct) => {
            if (cartProduct?.productId === productIdentifier) {
              return acc + (cartProduct?.quantity || 0);
            }
            return acc;
          }, 0);
          setProductQuantityInCart(total);
        } else {
          setProductQuantityInCart(productInCart.quantity);
        }

        if (productInCart.variationId) {
          setCartItemId(productInCart.variationId);
        } else {
          setCartItemId(productInCart.productId);
        }
      } else {
        resetCartQuantities();
        setInCart(false);
      }
    }
  }, [cart, productIdentifier]);

  const handleOnAddToCart = async () => {
    setIsAddingToCart(true);
    setPopoverProduct(product);
    try {
      // passing productDetail and selectedProductId because for variation product need the details of parent product
      await addToCart({
        product,
        quantity: addToCartMinQuantity,
        selectedProductId: productIdentifier,
        queryId: algoliaQueryID,
      });
      GTMProductAddToCartEvents(
        product.productName,
        productIdentifier,
        selectedWSP,
        product.wholesaler?.name,
        `/product/${product.productSlug}`,
        `${product?.productThumbnailUrl?.productThumbnailBaseUrl}/${product?.productThumbnailUrl?.productThumbnailName}`,
        currency?.name,
        userId
      );
      if (shouldSendAlgoliaInsights) {
        const eventData = {
          objectID: product?.id,
          ...(algoliaQueryID && { queryID: algoliaQueryID }),
        };
        pushAlgoliaAddToCartEvent(eventData, isAfterSearch);
      }
      setShowAddToCartPopover(true);

      setPreviousQuantity(addToCartMinQuantity);
    } catch (error) {
      // error was already handled in cart provider
      // no UI resets needed
      setShowAddToCartPopover(false);
    } finally {
      setIsAddingToCart(false);
    }
  };

  const handleOnAddToCartWithVariation = () => {
    setIsAddingToCart(true);
    setPopoverProduct(product);
    setShowAddToCartPopover(true);
  };

  const handleProductAlreadyAddedToCart = () => {
    setPopoverProduct(product);
    setShowAddToCartPopover(true);
  };

  const handleAddToCartPopoverClose = () => {
    setShowAddToCartPopover(false);
  };

  const handleRemoveFromCart = async () => {
    setInApiCall(true);
    try {
      await removeFromCart({ itemId: cartItemId });
      // reset quantites
      resetCartQuantities();
    } catch (error) {
      // reset quantity selector to previous state
      setQuantityToReset(new Number(previousQuantity));
    } finally {
      setInApiCall(false);
      handleAddToCartPopoverClose();
    }
  };

  const handleIncreaseInCart = async (actual, previous) => {
    setInApiCall(true);
    try {
      const quantity = actual - previous;
      await increaseInCart({ itemId: cartItemId, quantity });
      setPreviousQuantity(actual);
    } catch (error) {
      // reset quantity selector to previous state
      setQuantityToReset(new Number(previousQuantity));
    } finally {
      setInApiCall(false);
    }
  };

  const handleDecreaseInCart = async (actual, previous) => {
    setInApiCall(true);
    try {
      const quantity = previous - actual;
      await decreaseInCart({ itemId: cartItemId, quantity });
      setPreviousQuantity(actual);
    } catch (error) {
      // reset quantity selector to previous state
      setQuantityToReset(new Number(previousQuantity));
    } finally {
      setInApiCall(false);
    }
  };

  const handleOnClickQuantitySelector = async ({ previous, actual }) => {
    if (shouldTriggerCartApi) {
      setIsSelectingQuantity(true);

      if (Number.isInteger(actual) && Number.isInteger(previous) && actual !== previous) {
        if (actual < addToCartMinQuantity) {
          await handleRemoveFromCart();
        } else if (actual > previous) {
          await handleIncreaseInCart(actual, previous);
        } else if (previous > actual) {
          await handleDecreaseInCart(actual, previous);
        }
      }

      setIsSelectingQuantity(false);
      setShouldTriggerCartApi(false);
    }
  };

  const quantitySelectorHelper = ({ actual }) => {
    validateStockAmount({
      product,
      cartItemId,
      hasVariations,
      productQuantity: actual,
      handleStockError: setStockError,
    });
    setActualQuantity(actual);
    setShouldTriggerCartApi(true);
  };

  useDebounce(async () => handleOnClickQuantitySelector({ actual: actualQuantity, previous: previousQuantity }), 400, [
    actualQuantity,
    previousQuantity,
  ]);

  const refProductsUrl = algoliaQueryID
    ? `/product/${product.productSlug}?query-id=${algoliaQueryID}`
    : refProductsNewUrl || `/product/${product?.productSlug}`;

  // redirects
  const handleRedirectToHref = () => {
    if (product?.productSlug) {
      GTMProductEvents(
        product.productName,
        productIdentifier,
        selectedWSP,
        product.wholesaler?.name,
        `${product?.productThumbnailUrl?.productThumbnailBaseUrl}/${product?.productThumbnailUrl?.productThumbnailName}`,
        `/product/${product?.productSlug}`,
        `/store/${product?.brandSlug}`,
        currency.name,
        'click',
        userId
      );

      router.push(refProductsUrl);
    }
  };

  const handleRedirectToBrand = () => {
    if (product?.brandSlug) {
      // const algoliaBrandClickEventParams = [
      //   'brand',
      //   {
      //     objectID: product?.wholesaler?.id,
      //     ...(algoliaQueryID && { queryID: algoliaQueryID }),
      //     position: index + 1,
      //   },
      //   isAfterSearch,
      // ];
      // Disabling algolia brand click event temporarily
      // if (shouldSendAlgoliaInsights) {
      //   pushAlgoliaClickEvent(...algoliaBrandClickEventParams);
      // }

      const brandCardLink = algoliaQueryID
        ? `/store/${product?.brandSlug}?query-id=${algoliaQueryID}`
        : `/store/${product?.brandSlug}`;

      router.push(brandCardLink);
    }
  };

  const handleOnLinkClick = useCallback(() => {
    handleItemIdChange(product?.id);
    if (product?.productSlug) {
      GTMProductEvents(
        product?.productName,
        productIdentifier,
        selectedWSP,
        product.wholesaler?.name,
        `${product?.productThumbnailUrl?.productThumbnailBaseUrl}/${product?.productThumbnailUrl?.productThumbnailName}`,
        `/product/${product?.productSlug}`,
        `/store/${product?.brandSlug}`,
        currency.name,
        'click',
        userId
      );
    }
    if (shouldSendAlgoliaInsights) {
      pushAlgoliaClickEvent(
        'product',
        {
          objectID: product?.id,
          ...(algoliaQueryID && { queryID: algoliaQueryID }),
          position: index + 1,
        },
        isAfterSearch
      );
    }

    setProductsVisited(product?.id);
    router.push(refProductsUrl);
  }, [
    handleItemIdChange,
    product?.productSlug,
    product?.productName,
    productIdentifier,
    selectedWSP,
    product?.wholesaler?.name,
    userId,
    product?.id,
    refProductsUrl,
    router,
    setProductsVisited,
  ]);

  const getConditionalWrapper = useCallback(
    (children) => (
      <Box
        onClick={() => {
          if (shouldSendAlgoliaInsights) {
            pushAlgoliaClickEvent(
              'product',
              {
                objectID: product?.id,
                ...(algoliaQueryID && { queryID: algoliaQueryID }),
                position: index + 1,
              },
              isAfterSearch
            );
          }
          handleShowSignupPopup();
        }}
      >
        {children}
      </Box>
    ),
    [handleShowSignupPopup]
  );

  const getDefaultWrapper = useCallback(
    (children) => (
      <Link
        aria-label={product.productSlug}
        data-testid="product-detail-link"
        underline="none"
        onClick={handleOnLinkClick}
        href={refProductsUrl}
        passHref
      >
        {children}
      </Link>
    ),
    [handleOnLinkClick, product?.productSlug, refProductsUrl]
  );

  const productSubtotal = () =>
    getProductSubtotal({
      quantity: actualQuantity,
      productId: productIdentifier,
      productPrice: selectedWSP * 100, // convert back to cents
      productDiscountedPrice: selectedDiscountPrice * 100, // convert back to cents
      productBulkDiscountMinOrderQuantity: product?.productBulkDiscount?.productBulkDiscountMinOrderQuantity,
      productBulkDiscountPercentage: product?.productBulkDiscount?.productBulkDiscountPercentage,
    });

  const wholesalerSummary = () => {
    const prodList = getCartItemsWithCommonWholesalerId({
      cart,
      wholesalerId: wholesalerIdentifier,
      currencyName: currency?.name,
    });

    // protect against empty cart
    if (!prodList?.length) {
      return { total: 0 };
    }

    return getWholesalerSummary(prodList, {
      percentage: product?.wholesaler?.storeDiscount[0]?.wholesalerStoreDiscountPercentage ?? 0,
      minOrderAmount: product?.wholesaler?.storeDiscount[0]?.wholesalerStoreDiscountMinOrderAmount ?? 0,
    });
  };

  const ProductCardLayoutProps = {
    sx,
    productCardVariant,
    product,
    index,
    popoverProduct,
    currencySymbol,
    selectedWSP,
    selectedDiscountPrice,
    handleRedirectToRegister,
    selectedRRP,
    quantityToReset,
    selectedMinOrderValue,
    conditionalWrapConfig: {
      condition: isUnregistered && showSignupPopup,
      conditionalWrapper: getConditionalWrapper,
      defaultWrapper: getDefaultWrapper,
    },
    inCart,
    inApiCall,
    inWishlist,
    stockError,
    setStockError,
    hasVariations,
    isAddingToCart,
    isAuthenticated,
    isSelectingQuantity,
    showAddToCartPopover,
    productSubtotal,
    handleOnAddToCart,
    wholesalerSummary,
    handleAddToWishlist,
    handleRedirectToHref,
    handleRemoveFromCart,
    handleRedirectToBrand,
    quantitySelectorHelper,
    handleRemoveFromWishlist,
    handleAddToCartPopoverClose,
    handleOnAddToCartWithVariation,
    handleProductAlreadyAddedToCart,
    algoliaQueryID,
    productQuantityInCart,
  };

  // Full UI
  if (!loading) {
    return <ProductCardLayout {...ProductCardLayoutProps} />;
  }

  // Loading
  return <ProductCardSkeleton isAuthenticated={isAuthenticated} sx={sx} />;
};

ProductCard.propTypes = {
  sx: PropTypes.shape({}),
  index: PropTypes.number.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string,
    WP_id: PropTypes.string,
    flair: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
    productSlug: PropTypes.string,
    brandSlug: PropTypes.string,
    productMinQuantity: PropTypes.number,
    productPrice: PropTypes.arrayOf(
      PropTypes.shape({
        productRRP: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        productWSP: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        productCurrency: PropTypes.string,
      })
    ),
    productDiscount: PropTypes.arrayOf(
      PropTypes.shape({
        productDiscountValue: PropTypes.number,
        productDiscountCurrency: PropTypes.string,
      })
    ),
    productCategories: PropTypes.arrayOf(PropTypes.string),
    productThumbnailUrl: PropTypes.shape({
      productThumbnailName: PropTypes.string,
      productThumbnailBaseUrl: PropTypes.string,
    }),
    productVariationLabels: PropTypes.arrayOf(PropTypes.string),
    productName: PropTypes.string.isRequired,
    wholesaler: PropTypes.shape({
      name: PropTypes.string,
      country: PropTypes.string,
      description: PropTypes.string,
      rating: PropTypes.string,
      id: PropTypes.string,
      wpId: PropTypes.string,
      logo: PropTypes.shape({
        wholesalerStoreLogoImage: PropTypes.string,
        wholesalerStoreLogoBasePath: PropTypes.string,
      }),
      minOrderValue: PropTypes.arrayOf(
        PropTypes.shape({
          wholesalerStoreMinOrderAmount: PropTypes.number,
          wholesalerStoreMinOrderCurrency: PropTypes.string,
        })
      ),
      storeDiscount: PropTypes.arrayOf(
        PropTypes.shape({
          wholesalerStoreDiscountPercentage: PropTypes.number,
          wholesalerStoreDiscountMinOrderAmount: PropTypes.number,
        })
      ),
    }),
    productBulkDiscount: PropTypes.shape({
      productBulkDiscountPercentage: PropTypes.number,
      productBulkDiscountMinOrderQuantity: PropTypes.number,
    }),
    variations: PropTypes.arrayOf(
      PropTypes.shape({
        WP_id: PropTypes.string,
        attributes: PropTypes.arrayOf(
          PropTypes.shape({
            productVariationLabels: PropTypes.arrayOf(PropTypes.string),
            productVariationValues: PropTypes.arrayOf(PropTypes.string),
            productMinQuantity: PropTypes.number,
            productLongDescription: PropTypes.string,
            productThumbnailUrl: PropTypes.shape({
              productThumbnailName: PropTypes.string,
              productThumbnailBaseUrl: PropTypes.string,
            }),
            productPrice: PropTypes.arrayOf(
              PropTypes.shape({
                productRRP: PropTypes.number,
                productWSP: PropTypes.number,
                productCurrency: PropTypes.string,
              })
            ),
            productDiscount: PropTypes.arrayOf(
              PropTypes.shape({
                productDiscountValue: PropTypes.number,
                productDiscountCurrency: PropTypes.string,
              })
            ),
          })
        ),
      })
    ),
    productInventory: PropTypes.shape({
      productStock: PropTypes.number,
      productInventoryTracked: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
  refProductsNewUrl: PropTypes.string,
  handleItemIdChange: PropTypes.func,
  productCardVariant: PropTypes.oneOf(['normal', 'small']),
  algoliaQueryID: PropTypes.string,
};

export default ProductCard;
