import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const MinOrderForCheckout = ({ movMessage = '', hasMetMov = false }) => {
  const theme = useTheme();
  return (
    <Typography variant="body2" color={hasMetMov ? theme.palette.success.light : 'error'}>
      {movMessage}
    </Typography>
  );
};

export default MinOrderForCheckout;

MinOrderForCheckout.propTypes = {
  movMessage: PropTypes.string,
  hasMetMov: PropTypes.bool,
};
