import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const RootStyle = styled('span')(({ theme, ownerState }) => {
  const { flair, sx } = ownerState;

  const styleFilled = () => ({
    color: flair.flairTextColor,
    backgroundColor: flair.flairBackgroundColor,
    sx,
  });

  return {
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 8,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightBold,

    ...styleFilled(),
  };
});

const virgoFlairDefaultPropSx = {
  top: 16,
  right: 16,
  zIndex: 1,
  position: 'absolute',
  textTransform: 'uppercase',
};

// ----------------------------------------------------------------------

const VirgoFlair = ({ flair, sx = virgoFlairDefaultPropSx, ...other }) => {
  return (
    <RootStyle ownerState={{ flair }} sx={sx} {...other}>
      {flair.flairTitle}
    </RootStyle>
  );
};

VirgoFlair.propTypes = {
  sx: PropTypes.shape({}),
  flair: PropTypes.shape({
    code: PropTypes.string,
    flairTitle: PropTypes.string,
    flairBackgroundColor: PropTypes.string,
    flairTextColor: PropTypes.string,
    tooltip: PropTypes.string,
  }).isRequired,
};

export default VirgoFlair;
