import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';

/**
 * @param base {string}
 * @param path {string}
 * @return {string}
 */
export const getImageOrDefault = ({ base = '', path = '' }) =>
  !isEmpty(base) && isString(base) && !isEmpty(path) && isString(path)
    ? `https://${base}/${path}`
    : `/assets/images/product/coming-soon.webp`;
