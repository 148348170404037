export const brandCardStyles = {
  root: {
    web: {
      width: 236,
      height: 358,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '6px',
      overflow: 'hidden',
    },
    mobile: {
      width: 172,
      height: 276,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '4.67px',
      overflow: 'hidden',
    },
  },
  imageContainer: {
    web: {
      width: '100%',
      height: 236,
      position: 'relative',
    },
    mobile: {
      width: '100%',
      height: 172,
      position: 'relative',
    },
  },
  flair: {
    web: {
      position: 'absolute',
      top: '13.98px',
      left: '12.72px',
      zIndex: 1,
      fontSize: '10px',
      fontWeight: 700,
      lineHeight: '18px',
      padding: '1px 8px',
      borderRadius: '6px',
      textAlign: 'center',
      textTransform: 'uppercase',
      margin: 0,
      height: '20px',
      boxSizing: 'border-box',
    },
    mobile: {
      position: 'absolute',
      top: '9px',
      left: '10px',
      zIndex: 1,
      fontSize: '8px',
      fontWeight: 700,
      lineHeight: '14px',
      padding: '0.78px 6.22px',
      borderRadius: '4.67px',
      textTransform: 'uppercase',
      margin: 0,
      height: '16px',
      boxSizing: 'border-box',
    },
  },
  brandInfo: {
    web: {
      py: '11px',
      px: '14px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '8px',
    },
    mobile: {
      py: '10px',
      px: '11px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
    },
  },
  followButton: {
    web: {
      margin: 0,
      padding: 0,
      width: '100%',
      height: '24px',
      fontSize: '10px',
      minHeight: 'unset',
      fontWeight: 700,
      borderRadius: '4px',
      maxWidth: '180px',
    },
    mobile: {
      margin: 0,
      padding: 0,
      width: '100%',
      height: '24px',
      fontSize: '10px',
      minHeight: 'unset',
      fontWeight: 700,
      borderRadius: '4px',
      maxWidth: '120px',
    },
  },
  brandTitle: {
    web: {
      margin: 0,
      fontSize: '16px',
      fontWeight: 700,
      color: '#161C24',
      height: '47px',
      lineHeight: '23px',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    mobile: {
      margin: 0,
      fontSize: '14px',
      fontWeight: 700,
      color: '#161C24',
      height: '36px',
      lineHeight: '17px',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  brandDetails: {
    web: {
      fontSize: '12px',
      lineHeight: '22px',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    mobile: {
      fontSize: '10px',
      lineHeight: '16px',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};
