/* eslint-disable react/destructuring-assignment */
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { usePopper } from 'react-popper';

// Material UI
import CloseIcon from '@mui/icons-material/Close';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// Hooks & Utils
import { ClickAwayListener, Portal } from '@mui/material';
import useAddToCartPopover from 'hooks/useAddToCartPopover';
import useConfiguredRoutes from 'hooks/useConfiguredRoutes';
import useCurrency from 'hooks/useCurrency';
import useResponsive from 'hooks/useResponsive';
import { amountToDisplay } from 'utils/cart';
import { noOp } from 'utils/functions';
import ViewShopButton from './ViewShopButton';

const customStyles = {
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popper: (theme) => ({
    zIndex: 1,
    maxWidth: '375px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '320px',
    },
    '&[data-popper-placement*="bottom"] .arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[data-popper-placement*="top"] .arrow': {
      bottom: 1,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        border: '1em solid transparent',
        borderColor: `transparent transparent ${theme.palette.background.paper} ${theme.palette.background.paper}`,
        boxShadow:
          '-3px 3px 1px -2px rgb(0 0 0 / 20%), -3px 2px 2px 0px rgb(0 0 0 / 14%), -3px 1px 5px 0px rgb(0 0 0 / 12%)',
        transformOrigin: '0 0',
        transform: 'rotate(-45deg)',
      },
    },
    '&[data-popper-placement*="right"] .arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[data-popper-placement*="left"] .arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  }),
};

const AddToCartPopover = ({
  open = false,
  inCart = true,
  anchorEl = noOp,
  children = null,
  hasMetMov = false,
  showSkeleton = false,
  brandSubtotal = 0,
  productSubtotal = null,
  showBackdropLoader = false,
  handleAddToCartPopoverClose = noOp,
}) => {
  const theme = useTheme();
  const router = useRouter();
  const isMobile = useResponsive('down', 'sm');
  const { cartRoute } = useConfiguredRoutes();
  const { popoverProduct } = useAddToCartPopover();

  const {
    currency: { symbol: currencySymbol },
  } = useCurrency();

  const handleCartClick = async () => {
    await router.push(cartRoute);
  };

  const { disableViewShop = false } = useAddToCartPopover();

  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } = usePopper(anchorEl, popperElement, {
    placement: isMobile ? 'top-end' : 'top-start',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 5 } },
      {
        name: 'offset',
        options: {
          offset: [...(isMobile ? [12] : [0]), 0],
        },
      },
      {
        name: 'flip',
        enabled: false,
      },
    ],
  });

  if (showSkeleton) {
    return (
      <Portal>
        <Fade in={open}>
          <Box
            sx={(t) => ({ ...styles.popper, ...customStyles.popper(t) })}
            ref={setPopperElement}
            {...attributes.popper}
          >
            <Box ref={setArrowElement} sx={{ ...styles.arrow, ...customStyles.arrow }} className="arrow" />
            <PopoverContainer elevation={2}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" fontWeight="bold" fontSize="1.125rem">
                  <Skeleton width="12ch" />
                </Typography>
                <IconButton
                  aria-label="close add to cart popover skeleton"
                  onClick={handleAddToCartPopoverClose}
                  data-testid="add-to-cart-popover__close-button--skeleton"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>

              <Stack marginTop={0.5} spacing={isMobile ? 0.5 : 0.75}>
                {children && children}
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="center" marginTop={1}>
                <Skeleton variant="rectangular" width="187px" height="44px" sx={{ borderRadius: '8px' }} />
              </Stack>
            </PopoverContainer>
          </Box>
        </Fade>
      </Portal>
    );
  }

  return (
    <Portal>
      <Fade in={open}>
        <Box
          ref={setPopperElement}
          {...attributes.popper}
          sx={(t) => ({ ...styles.popper, ...customStyles.popper(t) })}
        >
          <Box ref={setArrowElement} sx={{ ...styles.arrow, ...customStyles.arrow }} className="arrow" />
          <ClickAwayListener onClickAway={handleAddToCartPopoverClose} touchEvent={false} mouseEvent="onMouseUp">
            <PopoverContainer elevation={2}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" fontWeight="bold" fontSize="1.125rem">
                  Add To Cart
                </Typography>
                <IconButton
                  aria-label="close add to cart popover"
                  onClick={handleAddToCartPopoverClose}
                  data-testid="add-to-cart-popover__close-button"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>

              <Stack marginTop={0.5} spacing={isMobile ? 0.5 : 0.75}>
                {children && children}
              </Stack>

              {productSubtotal > 0 && (
                <ListWrapper direction="row" alignItems="center" justifyContent="space-between">
                  <Typography
                    variant="subtitle2"
                    textTransform="uppercase"
                    color={theme.palette.grey[800]}
                    fontSize="0.875rem"
                  >
                    Item Subtotal:
                  </Typography>
                  <Typography variant="subtitle2" textTransform="uppercase" color={theme.palette.grey[800]}>
                    <span className="notranslate">
                      {currencySymbol}
                      {amountToDisplay(productSubtotal)}
                    </span>
                  </Typography>
                </ListWrapper>
              )}

              {brandSubtotal > 0 && (
                <ListWrapper direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="h6" fontWeight="bold" textTransform="uppercase" fontSize="1.125rem">
                    Brand Subtotal:
                  </Typography>
                  <Typography variant="h6" fontWeight="bold" textTransform="uppercase">
                    <span className="notranslate">
                      {currencySymbol}
                      {amountToDisplay(brandSubtotal)}
                    </span>
                  </Typography>
                </ListWrapper>
              )}

              {hasMetMov && (
                <Stack direction="row" alignItems="center" justifyContent="center" marginTop={1}>
                  <Button
                    variant="contained"
                    startIcon={<LocalMallIcon />}
                    sx={{ borderRadius: '8px', alignItems: 'center' }}
                    size={isMobile ? 'medium' : 'large'}
                    onClick={handleCartClick}
                  >
                    Go to my basket
                  </Button>
                </Stack>
              )}

              {!disableViewShop && !hasMetMov && (
                <Stack direction="row" alignItems="center" justifyContent="center" marginTop={1}>
                  <ViewShopButton
                    inCart={inCart}
                    onClick={handleAddToCartPopoverClose}
                    href={`/store/${popoverProduct.brandSlug}?ref=product-popover`}
                  />
                </Stack>
              )}
              {showBackdropLoader && (
                <StyledBackdrop open={showBackdropLoader}>
                  <CircularProgress color="inherit" />
                </StyledBackdrop>
              )}
            </PopoverContainer>
          </ClickAwayListener>
        </Box>
      </Fade>
    </Portal>
  );
};

export default AddToCartPopover;

const PopoverContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: '8px',
  backgroundColor: 'white',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.75),
  },
}));

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  color: '#fff',
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'rgba(0, 0, 0, 0.10)',
  borderRadius: '8px',
  position: 'absolute',
}));

const ListWrapper = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(0.375)} ${theme.spacing(1)}`,
  marginLeft: theme.spacing(-1),
  marginRight: theme.spacing(-1),
  marginTop: theme.spacing(0.75),
  backgroundColor: '#F7F7F7',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
    marginLeft: theme.spacing(-0.75),
    marginRight: theme.spacing(-0.75),
  },
}));

AddToCartPopover.propTypes = {
  open: PropTypes.bool,
  inCart: PropTypes.bool,
  children: PropTypes.node,
  hasMetMov: PropTypes.bool,
  showSkeleton: PropTypes.bool,
  brandSubtotal: PropTypes.number,
  productSubtotal: PropTypes.number,
  showBackdropLoader: PropTypes.bool,
  handleAddToCartPopoverClose: PropTypes.func,
  anchorEl: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
};
