import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useCurrency from 'hooks/useCurrency';
import { splitPrice } from 'utils/functions';

const styles = {
  strike: {
    textDecoration: 'line-through',
    textDecorationStyle: 'solid',
    variant: 'body1',
  },
};

const ProductPrice = ({ selectedDiscountedWSP = 0, selectedWSP = 0 }) => {
  const { currency } = useCurrency();

  if (selectedDiscountedWSP && selectedWSP) {
    return (
      <Stack direction="row" spacing={1} alignItems="baseline">
        <Typography color="textPrimary" className="priceTag" sx={styles.strike}>
          <span className="notranslate">
            WSP {currency?.symbol}
            {selectedWSP}
          </span>
        </Typography>
        <Typography className="singlePagePriceTag" variant="body1" fontSize={16} align="left">
          <span className="notranslate">
            WSP {currency?.symbol}
            {splitPrice(selectedDiscountedWSP).price}.<span>{splitPrice(selectedDiscountedWSP).floating}</span>
          </span>
        </Typography>
      </Stack>
    );
  }

  return (
    selectedWSP && (
      <Typography variant="body1" className="singlePagePriceTag" fontSize={16} align="left">
        <span className="notranslate">
          WSP {currency?.symbol}
          {selectedWSP}
        </span>
      </Typography>
    )
  );
};

export default ProductPrice;

ProductPrice.propTypes = {
  selectedWSP: PropTypes.number,
  selectedDiscountedWSP: PropTypes.number,
};
