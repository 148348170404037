import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import { noOp } from 'utils/functions';

import { useTheme } from '@mui/material/styles';
import useMovMessage from 'hooks/useMovMessage';

import AddToCartPopover from '../AddToCartPopover';

const AddToCartPopoverWithoutVariations = ({
  open = false,
  children = null,
  anchorEl = noOp,
  brandSubtotal = 0,
  productSubtotal = null,
  productOrderQuantity = null,
  showBackdropLoader = false,
  brandMinOrderValue = 0,
  handleRedirectToBrand = noOp,
  handleAddToCartPopoverClose = noOp,
}) => {
  const { hasMetMov, movMessage } = useMovMessage({
    brandSubtotal,
    brandMinOrderValue,
  });

  const theme = useTheme();

  return (
    <AddToCartPopover
      open={open}
      anchorEl={anchorEl}
      hasMetMov={hasMetMov}
      brandSubtotal={brandSubtotal}
      productSubtotal={productSubtotal}
      productOrderQuantity={productOrderQuantity}
      showBackdropLoader={showBackdropLoader}
      handleRedirectToBrand={handleRedirectToBrand}
      handleAddToCartPopoverClose={handleAddToCartPopoverClose}
    >
      <Typography variant="body2" color={hasMetMov ? theme.palette.success.light : 'error'}>
        {movMessage}
      </Typography>
      {children}
    </AddToCartPopover>
  );
};

export default AddToCartPopoverWithoutVariations;

AddToCartPopoverWithoutVariations.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  brandSubtotal: PropTypes.number,
  productSubtotal: PropTypes.number,
  productOrderQuantity: PropTypes.number,
  showBackdropLoader: PropTypes.bool,
  brandMinOrderValue: PropTypes.number,
  handleRedirectToBrand: PropTypes.func,
  handleAddToCartPopoverClose: PropTypes.func,
  anchorEl: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
};
