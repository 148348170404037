import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { alpha, Button, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import useAuth from 'hooks/useAuth';
import useCart from 'hooks/useCart';
import useProduct from 'hooks/useProduct';
import useCurrency from 'hooks/useCurrency';
import useMovMessage from 'hooks/useMovMessage';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useAddToCartPopover from 'hooks/useAddToCartPopover';
import useQuantitySelector from 'hooks/useQuantitySelector';

import {
  noOp,
  setPriceInDesiredCurrency,
  getMinMaxPriceFromVariations,
  getProductSubtotal,
  getCartItemsWithCommonWholesalerId,
} from 'utils/functions';
import { isProductOutOfStock, validateStockAmount } from 'utils/stockValidation';
import { pushAlgoliaAddToCartEvent } from 'utils/gtm';
import { getWholesalerSummary } from '@creoate/cart-lib/discounts';

// Components
import QuantitySelector from 'components/QuantitySelector';
import MinOrderInfo from './MinOrderInfo';
import PopoverPrice from './PopoverPrice';
import SelectVariations from './SelectVariations';
import AddToCartPopover from '../AddToCartPopover';
import MinOrderForCheckout from '../MinOrderForCheckout';

const addToCartPopoverWithVariationsDefaultPropStockError = [];

const AddToCartPopoverWithVariations = ({
  open = false,
  anchorEl = noOp,
  stockError = addToCartPopoverWithVariationsDefaultPropStockError,
  setStockError,
  handleRedirectToBrand = noOp,
  handleAddToCartPopoverClose = noOp,
  algoliaQueryID = null,
}) => {
  const theme = useTheme();
  const { user } = useAuth();
  const { popoverProduct } = useAddToCartPopover();
  const { currency, exchangeRates } = useCurrency();
  const [canAddToCart, setCanAddToCart] = useState(false);
  const [variationIdInDisplay, setVariationIdInDisplay] = useState(0);
  const [selectedVariationOptions, setSelectedVariationOptions] = useState({});
  const [currencySymbol, setCurrencySymbol] = useState(currency?.symbol || '£');
  const isAfterSearch = !!algoliaQueryID;

  // price data
  const [selectedWSP, setSelectedWSP] = useState(0);
  const [selectedDiscountedWSP, setSelectedDiscountedWSP] = useState(0);
  const [selectedMinOrderValue, setSelectedMinOrderValue] = useState(0);
  const [variationsPrice, setVariationsPrice] = useState({ minPrice: '', maxPrice: '' });

  // cart data & handlers
  const [inCart, setInCart] = useState(false);

  const { cart } = useCart();

  const {
    inApiCall,
    setCartItemId,
    isAddingToCart,
    actualQuantity,
    quantityToReset,
    handleOnAddToCart,
    isSelectingQuantity,
    resetCartQuantities,
    handleRemoveFromCart,
    quantitySelectorHelper,
    setAddToCartMinQuantity,
    setProductInCartQuantities,
  } = useQuantitySelector();

  const stockErrorMessage = stockError?.find((error) => error?.productId === variationIdInDisplay);

  const handleVariationSelect = (event) => {
    const [type, value] = event.target.value.split('#');

    const newVariationData = {
      ...selectedVariationOptions,
    };
    newVariationData[type] = value;

    setSelectedVariationOptions(newVariationData);
  };

  // configs
  const { IS_WORDPRESS_CONNECTED, SEARCH_RESULTS_ALGOLIA: isAlgoliaEnabled } = useFeatureFlags();

  // Send Algolia Insights events only from search results page
  const shouldSendAlgoliaInsights = isAlgoliaEnabled;

  const { data: product, isLoading } = useProduct(popoverProduct?.productSlug, {
    shippingCountryCode: user?.shippingCountry,
  });
  // Stock and pack data
  const minimumOrderQuantity = useMemo(
    () => Math.max(product?.productMinQuantity || 1, 1),
    [product?.productMinQuantity]
  );
  const maxStockAvailable = useMemo(
    () =>
      product?.productInventory?.productInventoryTracked === 'yes' ? product?.productInventory?.productStock || 0 : 0,
    [product?.productInventory?.productInventoryTracked, product?.productInventory?.productStock]
  );
  const productOrderQuantity = useMemo(
    () => Math.max(product?.productOrderQuantity || 1, 1),
    [product?.productOrderQuantity]
  );

  const productIdentifier = IS_WORDPRESS_CONNECTED ? product?.WP_id : product?.id;
  const wholesalerIdentifier = IS_WORDPRESS_CONNECTED ? product?.wholesaler?.wpId : product?.wholesaler?.id;

  // get variations min and max price
  useEffect(() => {
    if (product?.variations?.length > 0 && currency?.name) {
      const { minPrice, maxPrice } = getMinMaxPriceFromVariations(product?.variations, currency?.name);
      setVariationsPrice({ minPrice, maxPrice });
    }
  }, [currency?.name, product?.variations]);

  // react to currency change
  useEffect(() => {
    if (currency?.symbol) {
      setCurrencySymbol(currency.symbol);
    }
    if (currency?.name) {
      // WSP
      const wspInSelectedCurrency = product?.productPrice
        ?.filter((x) => x.productWSP > 0 && x.productCurrency === currency.name)
        .pop();

      if (wspInSelectedCurrency) {
        setSelectedWSP(Number(wspInSelectedCurrency.productWSP) / 100);
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            product?.productPrice,
            'productWSP',
            'productCurrency',
            currency.name,
            setSelectedWSP,
            exchangeRates
          );
        })();
      }

      // discounted price
      const discountedPriceInSelectedCurrency = product?.productDiscount
        ?.filter((x) => x.productDiscountValue > 0 && x.productDiscountCurrency === currency.name)
        .pop();

      if (discountedPriceInSelectedCurrency) {
        setSelectedDiscountedWSP(Number(discountedPriceInSelectedCurrency.productDiscountValue / 100));
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            product?.productDiscount,
            'productDiscountValue',
            'productDiscountCurrency',
            currency.name,
            setSelectedDiscountedWSP,
            exchangeRates
          );
        })();
      }

      // min order
      const minOrderInSelectedCurrency = product?.wholesaler?.minOrderValue
        ?.filter((x) => x.wholesalerStoreMinOrderAmount > 0 && x.wholesalerStoreMinOrderCurrency === currency.name)
        .pop();

      if (minOrderInSelectedCurrency) {
        setSelectedMinOrderValue(Number(minOrderInSelectedCurrency.wholesalerStoreMinOrderAmount) / 100);
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            product?.wholesaler?.minOrderValue,
            'wholesalerStoreMinOrderAmount',
            'wholesalerStoreMinOrderCurrency',
            currency.name,
            setSelectedMinOrderValue,
            exchangeRates
          );
        })();
      }
    }
  }, [currency, product?.productDiscount, product?.productPrice, product?.wholesaler?.minOrderValue, exchangeRates]);

  // react to variation changes
  useEffect(() => {
    if (Object.keys(selectedVariationOptions).length === product?.variationsMetadata?.length) {
      const selectedValues = Object.values(selectedVariationOptions);

      const found = product?.variations
        .filter((variation) => variation.attributes.productVariationValues.every((x) => selectedValues.includes(x)))
        .flat(1)
        .pop();

      const isOutOfStock = isProductOutOfStock({ product: found });

      if (!found || isOutOfStock) {
        setCanAddToCart(false);
      }

      const variationIdentifier = IS_WORDPRESS_CONNECTED ? found?.WP_id : found?.id;

      setVariationIdInDisplay(variationIdentifier);

      if (isOutOfStock) {
        setStockError((prev) => {
          return prev.some((p) => p.productId === variationIdentifier)
            ? prev
            : [
                ...prev,
                {
                  productId: variationIdentifier,
                  message: 'Out of stock',
                },
              ];
        });
      }

      // WSP prices
      const wspInSelectedCurrency = found?.attributes?.productPrice
        ?.filter((x) => x.productWSP > 0 && x.productCurrency === currency.name)
        .pop();

      if (wspInSelectedCurrency) {
        setSelectedWSP(Number(wspInSelectedCurrency.productWSP) / 100);
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            found?.attributes?.productPrice,
            'productWSP',
            'productCurrency',
            currency.name,
            setSelectedWSP,
            exchangeRates
          );
        })();
      }

      // discounted price
      const discountedPriceInSelectedCurrency = found?.attributes?.productDiscount
        ?.filter((x) => x.productDiscountValue > 0 && x.productDiscountCurrency === currency.name)
        .pop();

      if (discountedPriceInSelectedCurrency) {
        setSelectedDiscountedWSP(Number(discountedPriceInSelectedCurrency.productDiscountValue / 100));
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            found?.attributes?.productDiscount,
            'productDiscountValue',
            'productDiscountCurrency',
            currency.name,
            setSelectedDiscountedWSP,
            exchangeRates
          );
        })();
      }

      // cart data
      setCartItemId(variationIdentifier);

      const foundInCart = cart
        .filter((x) => x.productId === productIdentifier && x.variationId === variationIdentifier)
        .pop();

      if (foundInCart && Object.keys(foundInCart).length > 0) {
        setInCart(true);
        setCanAddToCart(false);
        setProductInCartQuantities(foundInCart);

        validateStockAmount({
          product,
          cartItemId: variationIdentifier,
          hasVariations: true,
          productQuantity: foundInCart.quantity,
          handleStockError: setStockError,
        });
      } else {
        resetCartQuantities();
        setInCart(false);
        // if product out of stock then disable addToBasket button
        setCanAddToCart(!isOutOfStock);

        const minQuantity = found?.attributes?.productMinQuantity > 0 ? found?.attributes?.productMinQuantity : 1;
        setAddToCartMinQuantity(minQuantity);
      }
    }
  }, [
    cart,
    setCartItemId,
    currency.name,
    exchangeRates,
    productIdentifier,
    product.variations,
    resetCartQuantities,
    IS_WORDPRESS_CONNECTED,
    setAddToCartMinQuantity,
    selectedVariationOptions,
    setProductInCartQuantities,
    product?.variationsMetadata?.length,
  ]);

  // react to cart updates
  useEffect(() => {
    if (cart) {
      const variationInCart = cart
        ?.filter((x) => x.productId === productIdentifier && x.variationId === variationIdInDisplay)
        .pop();

      // variation in display?
      if (variationInCart && Object.keys(variationInCart).length > 0) {
        setCartItemId(variationInCart.variationId);

        // handle variation options
        const variationOptions = {};

        const variationDetails = product.variations.find((x) => {
          const variationId = IS_WORDPRESS_CONNECTED ? x.WP_id : x.id;
          return variationId === variationInCart.variationId;
        });

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < variationDetails?.attributes?.productVariationLabels?.length; i++) {
          const label = variationDetails?.attributes?.productVariationLabels?.[i];
          const value = variationDetails?.attributes?.productVariationValues?.[i];
          variationOptions[label] = value;
        }

        validateStockAmount({
          product,
          cartItemId: variationInCart.variationId,
          hasVariations: true,
          productQuantity: variationInCart.quantity,
          handleStockError: setStockError,
        });

        setSelectedVariationOptions(variationOptions);
      } else {
        resetCartQuantities();
        setInCart(false);
      }
    }
  }, [
    cart,
    setCartItemId,
    productIdentifier,
    product.variations,
    resetCartQuantities,
    variationIdInDisplay,
    IS_WORDPRESS_CONNECTED,
  ]);

  const productSubtotal = () =>
    getProductSubtotal({
      productId: productIdentifier,
      productPrice: selectedWSP * 100, // convert back to cents
      productDiscountedPrice: selectedDiscountedWSP * 100, // convert back to cents
      quantity: actualQuantity,
      productBulkDiscountMinOrderQuantity: product?.productBulkDiscount?.productBulkDiscountMinOrderQuantity,
      productBulkDiscountPercentage: product?.productBulkDiscount?.productBulkDiscountPercentage,
    });

  // const { total: brandSubtotal } = getWholesalerSummary(
  //   getCartItemsWithCommonWholesalerId({ cart, wholesalerId: wholesalerIdentifier, currencyName: currency?.name }),
  //   {
  //     percentage: product?.wholesaler?.storeDiscount[0]?.wholesalerStoreDiscountPercentage ?? 0,
  //     minOrderAmount: product?.wholesaler?.storeDiscount[0]?.wholesalerStoreDiscountMinOrderAmount ?? 0,
  //   }
  // );

  const handleAddToCartPopover = (addToCartProduct) => {
    handleOnAddToCart(addToCartProduct, algoliaQueryID);
    if (shouldSendAlgoliaInsights) {
      const eventData = {
        objectID: product?.id,
        ...(algoliaQueryID && { queryID: algoliaQueryID }),
      };
      pushAlgoliaAddToCartEvent(eventData, isAfterSearch);
    }
  };

  const getBrandSubtotal = () => {
    const prodList = getCartItemsWithCommonWholesalerId({
      cart,
      wholesalerId: wholesalerIdentifier,
      currencyName: currency?.name,
    });

    // protect against empty cart
    if (prodList?.length === 0) {
      return 0;
    }

    return getWholesalerSummary(
      getCartItemsWithCommonWholesalerId({ cart, wholesalerId: wholesalerIdentifier, currencyName: currency?.name }),
      {
        percentage: product?.wholesaler?.storeDiscount[0]?.wholesalerStoreDiscountPercentage ?? 0,
        minOrderAmount: product?.wholesaler?.storeDiscount[0]?.wholesalerStoreDiscountMinOrderAmount ?? 0,
      }
    ).total;
  };
  const brandSubtotal = getBrandSubtotal();

  const brandMinOrderValue = product?.wholesaler?.minOrderValue.find(
    (o) => o.wholesalerStoreMinOrderCurrency === currencySymbol
  )?.wholesalerStoreMinOrderAmount;

  const { hasMetMov, movMessage } = useMovMessage({
    brandSubtotal,
    brandMinOrderValue: selectedMinOrderValue * 100, // convert back to cents
  });

  return (
    <AddToCartPopover
      open={open}
      inCart={inCart}
      anchorEl={anchorEl}
      hasMetMov={hasMetMov}
      showSkeleton={isLoading}
      brandSubtotal={brandSubtotal}
      productOrderQuantity={productOrderQuantity}
      showBackdropLoader={inApiCall}
      brandMinOrderValue={brandMinOrderValue}
      handleRedirectToBrand={handleRedirectToBrand}
      handleAddToCartPopoverClose={handleAddToCartPopoverClose}
      productSubtotal={isEmpty(selectedVariationOptions) ? null : productSubtotal()}
    >
      {!isLoading &&
        (inCart ? (
          <MinOrderForCheckout hasMetMov={hasMetMov} movMessage={movMessage} />
        ) : (
          !isEmpty(selectedVariationOptions) &&
          !hasMetMov && <MinOrderInfo selectedMinOrderValue={selectedMinOrderValue} currency={currency} />
        ))}

      <Stack direction="column" mb={0.5}>
        <PopoverPrice
          currency={currency}
          isLoading={isLoading}
          selectedWSP={selectedWSP}
          variationsPrice={variationsPrice}
          selectedDiscountedWSP={selectedDiscountedWSP}
          selectedVariationOptions={selectedVariationOptions}
        />
      </Stack>

      {product?.variationsMetadata?.map(({ type, values }) => (
        <SelectVariations
          label={type}
          selectOptions={values}
          key={`${type}#${values[0]}`}
          onChange={handleVariationSelect}
          selectedVariationOptions={selectedVariationOptions}
        />
      ))}

      {inCart ? (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <QuantitySelector
            type="light"
            sx={{
              border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
              borderRadius: '8px',
            }}
            initialValue={minimumOrderQuantity}
            minimumValue={minimumOrderQuantity}
            maximumValue={maxStockAvailable}
            multipleOf={productOrderQuantity}
            isDisabled={isSelectingQuantity}
            quantityToReset={quantityToReset}
            onChange={quantitySelectorHelper}
          />
          <IconButton aria-label="delete cart item" onClick={handleRemoveFromCart}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ) : (
        <Button
          fullWidth
          size="large"
          type="button"
          color="primary"
          variant="contained"
          sx={{ borderRadius: '8px' }}
          onClick={() => handleAddToCartPopover(product)}
          loading={isAddingToCart}
          disabled={!canAddToCart}
          data-testid="add-to-cart-button-with-variation"
        >
          <span className="notranslate">Add to basket</span>
        </Button>
      )}

      {/* out of stock / stock quantity message */}
      {stockErrorMessage && (
        <Typography data-testid="stock-error-msg-A2CPopover-variations" variant="body2" color="#d32f2f">
          {stockErrorMessage?.message}
        </Typography>
      )}
    </AddToCartPopover>
  );
};

export default AddToCartPopoverWithVariations;

AddToCartPopoverWithVariations.propTypes = {
  open: PropTypes.bool,
  stockError: PropTypes.shape([]),
  handleRedirectToBrand: PropTypes.func,
  handleAddToCartPopoverClose: PropTypes.func,
  anchorEl: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  setStockError: PropTypes.func.isRequired,
  algoliaQueryID: PropTypes.string,
};
