// import React from 'react';
import PropTypes from 'prop-types';

const ConditionalWrap = ({ condition = false, conditionalWrapper, defaultWrapper, children }) =>
  condition ? conditionalWrapper(children) : defaultWrapper(children);

export default ConditionalWrap;

ConditionalWrap.propTypes = {
  condition: PropTypes.bool,
  conditionalWrapper: PropTypes.func.isRequired,
  defaultWrapper: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
