export const normalCardStyles = {
  productTitle: {
    web: {
      margin: 0,
      fontSize: '16px',
      fontWeight: 700,
      color: '#161C24',
      minHeight: '37.94px',
      height: '100%',
      lineHeight: '18.97px',
    },
    mobile: {
      margin: 0,
      fontSize: '14px',
      fontWeight: 700,
      color: '#161C24',
      minHeight: '34px',
      height: '100%',
      lineHeight: '16.8px',
    },
  },
  brandName: {
    web: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '15.14px',
      textDecoration: 'underline',
      WebkitLineClamp: 1 /* number of lines to show */,
      overflow: 'hidden',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
    },
    mobile: {
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '15.14px',
      textDecoration: 'underline',
      WebkitLineClamp: 1 /* number of lines to show */,
      overflow: 'hidden',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
    },
  },
  brandData: {
    web: {
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '19.01px',
      color: '#4E4E4E',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    mobile: {
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '15.14px',
      color: '#4E4E4E',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  unauthenticatedButton: {
    web: {
      margin: 0,
      padding: 0,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '32px',
      borderRadius: '4px',
      width: '100%',
      height: '32px',
    },
    mobile: {
      margin: 0,
      padding: 0,
      fontWeight: 600,
      fontSize: '10px',
      lineHeight: '22px',
      borderRadius: '4px',
      width: '100%',
      height: '22px',
    },
  },
  rrPrice: {
    web: { fontSize: '10px', fontWeight: 400, lineHeight: '13.36px', color: '#4E4E4E' },
    mobile: { fontSize: '8px', fontWeight: 400, lineHeight: '11.26px', color: '#4E4E4E' },
  },
  wrappers: {
    web: {
      position: 'absolute',
      top: '10px',
      left: '14px',
      right: '14px',
      height: '100%',
      maxHeight: '28px',
      width: '100%',
      maxWidth: 'calc(100% - 28px)',
    },
    mobile: {
      position: 'absolute',
      top: '8px',
      left: '8px',
      right: '8px',
      height: '100%',
      maxHeight: '22px',
      width: '100%',
      maxWidth: 'calc(100% - 16px)',
    },
  },
  wishlistButton: {
    web: { width: '28px', height: '28px', left: undefined, right: 0, top: 0 },
    mobile: { left: undefined, right: 0, top: 0, width: '22px', height: '22px' },
  },
  wishlistIcon: {
    web: { width: '20px', height: '20px' },
    mobile: { width: '15.71px', height: '15.71px' },
  },
  infoWrapper: {
    web: { padding: '14px', gap: '8px' },
    mobile: { padding: '10px 11px 12px 11px', gap: '8px' },
  },
  priceStyle: {
    web: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '22.4px',
      display: 'flex',
      alignItems: 'flex-end',
      gap: '6px',
    },
    mobile: {
      fontSize: '14px',
      lineHeight: '19.31px',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'flex-end',
      gap: '6px',
    },
  },
};

export const smallCardStyles = {
  wrappers: {
    web: {
      position: 'absolute',
      top: '8px',
      left: '10px',
      right: '10px',
      height: '100%',
      maxHeight: '22px',
      width: '100%',
      maxWidth: '152px',
    },
    mobile: {
      position: 'absolute',
      top: '6.79px',
      left: '8.49px',
      right: '8.49px',
      height: '100%',
      maxHeight: '18.66px',
      width: '100%',
      maxWidth: '129.02px',
    },
  },
  rrPrice: {
    web: { fontSize: '8px', fontWeight: 400, lineHeight: '11.26px', color: '#4E4E4E' },
    mobile: { fontSize: '6px', fontWeight: 400, lineHeight: '9px', color: '#4E4E4E' },
  },

  productTitle: {
    web: {
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
      margin: 0,
      color: '#161C24',
      minHeight: '32px',
      height: '100%',
    },
    mobile: {
      fontWeight: 700,
      fontSize: '10px',
      lineHeight: '13px',
      margin: 0,
      color: '#161C24',
      minHeight: '27.16px',
      height: '100%',
    },
  },
  wishlistButton: {
    web: { width: '22px', height: '22px', left: undefined, right: 0, top: 0 },
    mobile: { left: undefined, right: 0, top: 0, width: '18.67px', height: '18.67px' },
  },
  wishlistIcon: {
    web: { width: '15.71px', height: '15.71px' },
    mobile: { width: '13.34px', height: '13.34px' },
  },
  infoWrapper: {
    web: { padding: '10px 8px 8px 8px', gap: '4px' },
    mobile: { padding: '8px 8px 8px 9px', gap: '4px' },
  },
  priceStyle: {
    web: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '19.31px',
    },
    mobile: { fontSize: '10px', lineHeight: '16px', fontWeight: 400 },
  },
};

export const priceStyles = {
  strike: {
    textDecoration: 'line-through',
    textDecorationStyle: 'solid' /* solid/double/dotted/dashed */,
    fontSize: '0.75rem',
    textDecorationColor: 'rgba(0,0,0,0.35)',
  },
  actions: {
    p: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (min-width: 1200px) and (max-width: 1367px)': {
      whiteSpace: 'nowrap',
    },
    '&:last-child': {
      p: 0,
    },
  },
  price: {
    display: 'flex',
    marginTop: '-0.5rem',
    alignItems: 'center',
    marginBottom: '-0.5rem',
    justifyContent: 'flex-start',
  },
};
