import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const MinOrderInfo = ({ selectedMinOrderValue = '', currency }) => {
  const theme = useTheme();

  return (
    <Typography variant="body2" color={theme.palette.info.light}>
      Minimum order value for this brand is&nbsp;
      <span className="notranslate">
        {currency.symbol}
        {selectedMinOrderValue}
      </span>
    </Typography>
  );
};

export default MinOrderInfo;

MinOrderInfo.propTypes = {
  selectedMinOrderValue: PropTypes.string,
  currency: PropTypes.shape({ symbol: PropTypes.string.isRequired }).isRequired,
};
