/* eslint-disable no-new-wrappers */
import useCart from 'hooks/useCart';
import { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';

const useQuantitySelector = () => {
  const [inApiCall, setInApiCall] = useState(false);
  const [cartItemId, setCartItemId] = useState(null);
  const [actualQuantity, setActualQuantity] = useState(null);
  const [previousQuantity, setPreviousQuantity] = useState(0);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [quantityToReset, setQuantityToReset] = useState(null);
  const [addToCartMinQuantity, setAddToCartMinQuantity] = useState(1);
  const [isSelectingQuantity, setIsSelectingQuantity] = useState(false);
  const [shouldTriggerCartApi, setShouldTriggerCartApi] = useState(false);

  const setProductInCartQuantities = useCallback((productInCart) => {
    setQuantityToReset(new Number(productInCart.quantity));
    setPreviousQuantity(productInCart.quantity);
    setActualQuantity(productInCart.quantity);
  }, []);

  const resetCartQuantities = useCallback(() => {
    setPreviousQuantity(0);
    setActualQuantity(null);
    setQuantityToReset(null);
  }, []);

  const { addToCart, removeFromCart, increaseInCart, decreaseInCart } = useCart();

  // HANDLER FUNCTIONS
  const handleOnAddToCart = useCallback(
    async (product, algoliaQueryID = null) => {
      setIsAddingToCart(true);
      try {
        // passing productDetail and selectedProductId because for variation product need the details of parent product
        await addToCart({
          product,
          quantity: addToCartMinQuantity,
          selectedProductId: cartItemId,
          queryId: algoliaQueryID,
        });
        // GTMProductAddToCartEvents(product.productName, productIdentifier, selectedWSP, product.wholesaler?.name, userId);
        setPreviousQuantity(addToCartMinQuantity);
      } catch (error) {
        // error was already handled in cart provider
        // no UI resets needed
      } finally {
        setIsAddingToCart(false);
      }
    },
    [addToCart, addToCartMinQuantity, cartItemId]
  );

  const handleRemoveFromCart = useCallback(async () => {
    setInApiCall(true);
    try {
      await removeFromCart({ itemId: cartItemId });
      // reset quantities
      resetCartQuantities();
    } catch (error) {
      // reset quantity selector to previous state
      setQuantityToReset(new Number(previousQuantity));
    } finally {
      setInApiCall(false);
    }
  }, [cartItemId, previousQuantity, removeFromCart, resetCartQuantities]);

  const handleIncreaseInCart = useCallback(
    async (actual, previous) => {
      setInApiCall(true);
      try {
        const quantity = actual - previous;
        await increaseInCart({ itemId: cartItemId, quantity });

        setPreviousQuantity(actual);
      } catch (error) {
        // reset quantity selector to previous state
        setQuantityToReset(new Number(previousQuantity));
      } finally {
        setInApiCall(false);
      }
    },
    [cartItemId, increaseInCart, previousQuantity]
  );

  const handleDecreaseInCart = useCallback(
    async (actual, previous) => {
      setInApiCall(true);
      try {
        const quantity = previous - actual;

        await decreaseInCart({ itemId: cartItemId, quantity });

        setPreviousQuantity(actual);
      } catch (error) {
        // reset quantity selector to previous state
        setQuantityToReset(new Number(previousQuantity));
      } finally {
        setInApiCall(false);
      }
    },
    [cartItemId, decreaseInCart, previousQuantity]
  );

  const handleOnClickQuantitySelector = async ({ previous, actual }) => {
    if (shouldTriggerCartApi) {
      setIsSelectingQuantity(true);

      if (Number.isInteger(actual) && Number.isInteger(previous) && actual !== previous) {
        if (actual < addToCartMinQuantity) {
          await handleRemoveFromCart();
        } else if (actual > previous) {
          await handleIncreaseInCart(actual, previous);
        } else if (previous > actual) {
          await handleDecreaseInCart(actual, previous);
        }
      }

      setIsSelectingQuantity(false);
      setShouldTriggerCartApi(false);
    }
  };

  const quantitySelectorHelper = ({ actual }) => {
    setActualQuantity(actual);
    setShouldTriggerCartApi(true);
  };

  useDebounce(async () => handleOnClickQuantitySelector({ actual: actualQuantity, previous: previousQuantity }), 400, [
    actualQuantity,
  ]);

  return {
    inApiCall,
    setCartItemId,
    isAddingToCart,
    actualQuantity,
    quantityToReset,
    handleOnAddToCart,
    isSelectingQuantity,
    resetCartQuantities,
    handleRemoveFromCart,
    quantitySelectorHelper,
    setAddToCartMinQuantity,
    setProductInCartQuantities,
  };
};

export default useQuantitySelector;
