import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { cloneElement, forwardRef, useImperativeHandle, useRef } from 'react';

// Material-UI components
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const styles = {
  container: {
    height: '100%',
    position: 'relative',
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    zIndex: 1,
    transform: 'translateY(-50%)',
    backgroundColor: '#FFF',
    border: '1px solid #DFE3E8',
    width: '25px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  arrowNext: {
    right: '0.75rem',
  },
  arrowPrev: {
    left: '0.75rem',
  },
  dot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#D9D9D9',
    display: 'inline-block',
    margin: '0 5px',
  },
  activeDot: {
    backgroundColor: '#242424',
  },
};

const BabyGlideCarousel = forwardRef(({ children, config }, ref) => {
  const sliderRef = useRef(null);

  const appendDots = (dots) => (
    <div
      style={{
        display: 'flow',
        padding: config?.isBrandCarousel ? '10px 10px 2rem' : '10px 10px 0px',
      }}
    >
      {dots.map((dot) =>
        cloneElement(dot, {
          style: {
            width: '12px',
            margin: '0 2.5px',
            listStyle: 'none',
          },
          key: dot.key,
        })
      )}
    </div>
  );

  const customPaging = (i) => (
    <div
      style={{
        ...styles.dot,
        ...(config?.isMobile ? { width: '8px', height: '8px' } : {}),
        ...(i === sliderRef.current?.innerSlider?.state.currentSlide ? styles.activeDot : {}),
      }}
    />
  );

  const settings = {
    dots: config?.bulletsLength > 1 && !config?.hideBullets,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: config?.isMobile || config?.swipeable,
    arrows: false,
    customPaging,
    appendDots,
  };

  useImperativeHandle(ref, () => ({
    goToSlide: (slideIndex) => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(slideIndex);
      }
    },
  }));

  return (
    <Box sx={styles.container}>
      <Slider ref={sliderRef} {...settings}>
        {children}
      </Slider>
      {!config.isMobile && config.bulletsLength > 1 && (
        <>
          <IconButton
            sx={{ ...styles.arrow, ...styles.arrowPrev }}
            onClick={(e) => {
              e.stopPropagation();
              sliderRef.current.slickPrev();
            }}
          >
            <ChevronLeftIcon color="primary" />
          </IconButton>
          <IconButton
            sx={{ ...styles.arrow, ...styles.arrowNext }}
            onClick={(e) => {
              e.stopPropagation();
              sliderRef.current.slickNext();
            }}
          >
            <ChevronRightIcon color="primary" />
          </IconButton>
        </>
      )}
    </Box>
  );
});

export default BabyGlideCarousel;

BabyGlideCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  activeIndex: PropTypes.number,
  config: PropTypes.shape({
    swipeable: PropTypes.bool,
    hideBullets: PropTypes.bool,
    bulletsLength: PropTypes.number,
    isMobile: PropTypes.bool,
    isBrandCarousel: PropTypes.bool,
  }),
};
