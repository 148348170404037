import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Input } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import styled from '@mui/system/styled';
import { isMultiple, nextMultiple, previousMultiple } from 'utils/math.utils';

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '.5rem',
  borderStyle: 'solid',
  color: 'white',
  transition: 'all 0.4s cubic-bezier(0.45, 0.46, 0.29, 0.97)',
  [theme.breakpoints.down('md')]: {
    minWidth: '.5rem',
  },
}));

const StyledIncreaseButton = styled(StyledButton)(() => ({
  borderTopLeftRadius: '0',
  borderTopRightRadius: '0.5rem',
  borderBottomLeftRadius: '0',
  borderBottomRightRadius: '0.5rem',
}));

const StyledDecreaseButton = styled(StyledButton)(() => ({
  borderTopLeftRadius: '0.5rem',
  borderTopRightRadius: '0',
  borderBottomLeftRadius: '0.5rem',
  borderBottomRightRadius: '0',
}));

const StyledQuantityField = styled(Input, {
  shouldForwardProp: (prop) => prop !== 'colorType',
})(({ colorType, theme }) => ({
  width: '40px',
  borderTop: `1px solid ${colorType === 'light' ? theme.palette.action.background : theme.palette.primary.dark}`,
  borderBottom: `1px solid ${colorType === 'light' ? theme.palette.action.background : theme.palette.primary.dark}`,
  textAlign: 'center',
  '& .MuiInput-input': {
    textAlign: 'center',
    transition: 'all 0.4s cubic-bezier(0.45, 0.46, 0.29, 0.97)',
  },
  '&.Mui-disabled': {
    borderTop: `1px solid ${theme.palette.action.disabledBackground}`,
    borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
  },
  transition: 'all 0.4s cubic-bezier(0.45, 0.46, 0.29, 0.97)',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.85rem',
  },
}));

const quantitySelectorDefaultPropSx = {};

const QuantitySelector = ({
  onChange,
  sx = quantitySelectorDefaultPropSx,
  initialValue = 0,
  minimumValue = 1,
  maximumValue = 0,
  multipleOf = 1,
  isDisabled = false,
  quantityToReset = null,
  type = 'dark',
}) => {
  const [quantity, setQuantity] = useState(initialValue);
  const [previousQuantity, setPreviousQuantity] = useState(initialValue);
  const theme = useTheme();

  const hasMaximumLimit = useMemo(() => maximumValue > 0, [maximumValue]);
  const multipleMinimumValue = useMemo(
    () => (isMultiple(minimumValue, multipleOf) ? minimumValue : nextMultiple(minimumValue, multipleOf)),
    [minimumValue, multipleOf]
  );
  const multipleMaximumValue = useMemo(
    () =>
      hasMaximumLimit && isMultiple(maximumValue, multipleOf)
        ? maximumValue
        : previousMultiple(maximumValue, multipleOf),
    [hasMaximumLimit, maximumValue, multipleOf]
  );

  useEffect(() => {
    if (quantityToReset !== null && quantityToReset !== undefined) {
      setQuantity(quantityToReset.valueOf());
    }
  }, [quantityToReset]);

  const onChangeHandler = (newQuantity) => {
    if (!onChange) return;
    onChange({
      actual: newQuantity,
      previous: previousQuantity,
    });
    setPreviousQuantity(newQuantity);
  };

  const increaseAmount = () => {
    // Abort if the quantity to increase is greater than the maximum value
    const newQuantity = nextMultiple(quantity, multipleOf);
    if (hasMaximumLimit && newQuantity > multipleMaximumValue) return;

    // Otherwise, increase the quantity to the next multiple of the given value
    setQuantity(newQuantity);
    onChangeHandler(newQuantity);
  };

  const decreaseAmount = () => {
    // Abort if the quantity to decrease is less than the minimum value
    const newQuantity = previousMultiple(quantity, multipleOf);
    if (newQuantity < multipleMinimumValue) return;

    setQuantity(newQuantity);
    onChangeHandler(newQuantity);
  };

  const updateAmount = (updateQuantity) => {
    // Adjust the quantity to the next multiple of the given value
    let newQuantity = isMultiple(updateQuantity, multipleOf)
      ? updateQuantity
      : nextMultiple(updateQuantity, multipleOf);

    // Guard against setting the quantity to a value greater than the maximum value or less than the minimum value
    newQuantity = Math.max(newQuantity, multipleMinimumValue);
    if (hasMaximumLimit) {
      newQuantity = Math.min(newQuantity, multipleMaximumValue);
    }

    // Set updated quantity and propagate the change to the parent component
    setQuantity(newQuantity);
    onChangeHandler(newQuantity);
  };

  const onQuantityChange = (event) => {
    const inputValue = event.target.value;
    // Allow only numbers text (even when pasting)
    if (/^\d*$/.test(inputValue)) {
      setQuantity(Number(inputValue));
    }
  };

  const onQuantityKeyDown = (event) => {
    // List of allowed keys and key combinations
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab', 'Delete', 'Enter'];
    const isCtrlCmdCombo =
      (event.ctrlKey || event.metaKey) && ['c', 'v', 'x', 'a', 'r'].includes(event.key.toLowerCase());

    if (
      !/^\d$/.test(event.key) && // Allow only numbers keys (0-9)
      !allowedKeys.includes(event.key) && // Allow editing/navigation keys
      !isCtrlCmdCombo // Allow combo keys (Ctrl/Cmd + [C, V, X, A])
    ) {
      event.preventDefault();
    }

    // If pressing "Enter", update the quantity
    if (event.key === 'Enter') {
      updateAmount(quantity);
    } else if (event.key === 'ArrowUp') {
      increaseAmount();
    } else if (event.key === 'ArrowDown') {
      decreaseAmount();
    }
  };

  const handleFocus = (event) => {
    // Select the text when the input is focused
    event.target.select();
  };

  return (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'row' }}>
      <StyledDecreaseButton
        id="virgo-quantity-selector-decrease-button"
        size="small"
        type="button"
        variant="contained"
        onClick={decreaseAmount}
        disabled={isDisabled || quantity <= multipleMinimumValue}
        aria-label="decrease quantity"
        data-testid="quantity-selector-decrease-button"
        sx={
          type === 'light' && {
            backgroundColor: '#ffffff',
            boxShadow: 0,
            '&:hover': {
              backgroundColor: '#919eab',
            },
          }
        }
      >
        <RemoveIcon
          id="virgo-quantity-selector-decrease-button"
          sx={{
            ...(type === 'light' ? { color: '#000000' } : {}),
            [theme.breakpoints.down('md')]: { fontSize: '1rem' },
          }}
        />
      </StyledDecreaseButton>
      <StyledQuantityField
        variant="standard"
        type="text"
        value={quantity}
        onChange={onQuantityChange}
        onBlur={() => updateAmount(quantity)}
        onKeyDown={onQuantityKeyDown}
        onFocus={handleFocus}
        disabled={isDisabled}
        disableUnderline
        inputMode="numeric"
        colorType={type}
        inputProps={{ 'data-testid': 'quantity-selector-display-field' }}
      />
      <StyledIncreaseButton
        id="virgo-quantity-selector-increase-button"
        size="small"
        type="button"
        variant="contained"
        onClick={increaseAmount}
        disabled={isDisabled || (hasMaximumLimit && quantity >= multipleMaximumValue)}
        aria-label="increase quantity"
        data-testid="quantity-selector-increase-button"
        sx={
          type === 'light' && {
            backgroundColor: '#ffffff',
            boxShadow: 0,
            '&:hover': {
              backgroundColor: '#919eab',
            },
          }
        }
      >
        <AddIcon
          id="virgo-quantity-selector-increase-button"
          sx={{
            ...(type === 'light' ? { color: '#000000' } : {}),
            [theme.breakpoints.down('md')]: { fontSize: '1rem' },
          }}
        />
      </StyledIncreaseButton>
    </Box>
  );
};

QuantitySelector.propTypes = {
  sx: PropTypes.shape({}),
  initialValue: PropTypes.number,
  minimumValue: PropTypes.number,
  maximumValue: PropTypes.number,
  multipleOf: PropTypes.number,
  quantityToReset: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
};

export default QuantitySelector;
