import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { noOp } from 'utils/functions';

const selectVariationsDefaultPropSelectedVariationOptions = {};
const selectVariationsDefaultPropSelectOptions = [''];

const SelectVariations = ({
  onChange = noOp,
  selectedVariationOptions = selectVariationsDefaultPropSelectedVariationOptions,
  label = '',
  selectOptions = selectVariationsDefaultPropSelectOptions,
}) => {
  return (
    <FormControl fullWidth size="small" data-testid="add-to-cart-popover__variations--select">
      <InputLabel id="attribute-select-label" sx={{ textTransform: 'capitalize' }}>
        {label}
      </InputLabel>
      <Select
        native
        label={label}
        sx={{ borderRadius: '8px' }}
        onChange={onChange}
        labelId="attribute-select-label"
        data-testid="add-to-cart-variation-select"
        value={selectedVariationOptions[label] ? `${label}#${selectedVariationOptions[label]}` : ''}
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <option value="" selected disabled hidden />
        {selectOptions.map((attribute) => (
          <option
            data-testid="add-to-cart-variation-select-items"
            key={`${label}-${attribute}`}
            value={`${label}#${attribute}`}
          >
            {attribute}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectVariations;

SelectVariations.propTypes = {
  onChange: PropTypes.func,
  selectedVariationOptions: PropTypes.shape({}),
  label: PropTypes.string,
  selectOptions: PropTypes.arrayOf(PropTypes.string),
};
