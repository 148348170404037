import PropTypes from 'prop-types';

// Material-UI
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

// Hooks
import useResponsive from 'hooks/useResponsive';

const wishListButtonDefaultPropSx = {};
const wishListButtonDefaultPropIconSx = {};

const WishListButton = ({
  sx = wishListButtonDefaultPropSx,
  inWishlist,
  handleRemoveFromWishlist,
  handleAddToWishlist,
  iconSx = wishListButtonDefaultPropIconSx,
  ...props
}) => {
  const isMobile = useResponsive('down', 'sm');

  const currentButton = inWishlist
    ? {
        'aria-label': 'Remove from wishlist',
        onClick: handleRemoveFromWishlist,
        'data-testid': 'remove-from-wishlist-button',
        children: <FavoriteIcon fontSize="inherit" sx={iconSx} />,
      }
    : {
        'aria-label': 'Add to wishlist',
        onClick: handleAddToWishlist,
        'data-testid': 'add-to-wishlist-button',
        children: <FavoriteBorderIcon fontSize="inherit" sx={iconSx} />,
      };

  return (
    <IconButton
      sx={{
        position: 'absolute',
        top: 10,
        left: 10,
        zIndex: 1,
        backgroundColor: '#FFFFFF!important',
        ...sx,
      }}
      size={`${isMobile ? 'small' : 'medium'}`}
      color="primary"
      {...currentButton}
      {...props}
    />
  );
};

WishListButton.propTypes = {
  sx: PropTypes.shape({}),
  iconSx: PropTypes.shape({}),
  inWishlist: PropTypes.bool.isRequired,
  handleRemoveFromWishlist: PropTypes.func.isRequired,
  handleAddToWishlist: PropTypes.func.isRequired,
};

export default WishListButton;
