import PropTypes from 'prop-types';
import AddToCartPopoverContext from 'context/AddToCartPopoverContext';
import { useMemo, useState } from 'react';

const AddToCartPopoverProvider = ({ children, disableViewShop = false }) => {
  const [popoverProduct, setPopoverProduct] = useState(null);

  const state = useMemo(
    () => ({
      popoverProduct,
      setPopoverProduct,
      disableViewShop,
    }),
    [popoverProduct, disableViewShop]
  );

  return <AddToCartPopoverContext.Provider value={state}>{children}</AddToCartPopoverContext.Provider>;
};

export default AddToCartPopoverProvider;

AddToCartPopoverProvider.propTypes = {
  children: PropTypes.node.isRequired,
  disableViewShop: PropTypes.bool,
};
