import { useEffect, useState } from 'react';
import { amountToDisplay } from 'utils/cart';
import useCurrency from './useCurrency';

const useMovMessage = ({ brandSubtotal, brandMinOrderValue }) => {
  const { currency } = useCurrency();
  const [movMessage, setMovMessage] = useState('');

  useEffect(() => {
    if (brandSubtotal >= brandMinOrderValue) {
      setMovMessage('Congrats, brand minimum met');
    } else {
      setMovMessage(
        `Add ${currency?.symbol}${amountToDisplay(
          brandMinOrderValue - brandSubtotal
        )} of any product to checkout this brand`
      );
    }
  }, [brandMinOrderValue, brandSubtotal, currency?.symbol]);

  return {
    hasMetMov: brandSubtotal >= brandMinOrderValue,
    movMessage,
  };
};

export default useMovMessage;
