import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Skeleton from '@mui/material/Skeleton';
import { amountToDisplay } from 'utils/cart';

import ProductPrice from '../../ProductPrice';

const popoverPriceDefaultPropVariationsPrice = {
  minPrice: 0,
  maxPrice: 0,
};

const popoverPriceDefaultPropSelectedVariationOptions = {};

const PopoverPrice = ({
  isLoading = false,
  selectedVariationOptions = popoverPriceDefaultPropSelectedVariationOptions,
  variationsPrice = popoverPriceDefaultPropVariationsPrice,
  selectedDiscountedWSP = 0,
  selectedWSP = 0,
  currency,
}) => {
  if (isLoading) {
    return <Skeleton />;
  }

  return isEmpty(selectedVariationOptions) ? (
    <span className="notranslate">
      WSP {currency.symbol}
      {amountToDisplay(variationsPrice.minPrice)} to {currency.symbol}
      {amountToDisplay(variationsPrice.maxPrice)}
    </span>
  ) : (
    <ProductPrice selectedDiscountedWSP={selectedDiscountedWSP} selectedWSP={selectedWSP} />
  );
};

export default PopoverPrice;

PopoverPrice.propTypes = {
  variationsPrice: PropTypes.shape({
    minPrice: PropTypes.number,
    maxPrice: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  selectedVariationOptions: PropTypes.shape({}),
  selectedDiscountedWSP: PropTypes.number,
  selectedWSP: PropTypes.number,
  currency: PropTypes.shape({ symbol: PropTypes.string.isRequired }).isRequired,
};
