import useSWR from 'swr';

import { bffFetcher } from 'utils/functions';

const useProduct = (slug = '', { shippingCountryCode } = {}) => {
  if (typeof slug !== 'string') {
    throw new SyntaxError(`'slug' must be of type 'string'. '${slug}' provided.`);
  } else if (slug === '') {
    throw new SyntaxError(`'slug' param is required 'string'.`);
  }

  const query = new URLSearchParams({
    ...(shippingCountryCode && { shippingCountryCode }),
  });

  const params = query.toString() ? `?${decodeURIComponent(query.toString())}` : '';

  const { data, error, mutate, isValidating } = useSWR(`/products/${slug}${params}`, bffFetcher);

  return {
    error,
    mutate,
    isValidating,
    data: data || {},
    isLoading: !error && !data,
  };
};

export default useProduct;
