import PropTypes from 'prop-types';

// Material-UI
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

// Hooks
import useResponsive from 'hooks/useResponsive';

const commonStyles = {
  root: {
    web: {
      width: 236,
      height: 358,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '16px',
      overflow: 'hidden',
    },
    mobile: {
      width: 172,
      height: 276,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '16px',
      overflow: 'hidden',
    },
  },
  brandInfo: {
    web: {
      padding: '16px',
      height: '122px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    mobile: {
      padding: '12px',
      height: '104px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  followButton: {
    web: {
      width: '90%',
      marginTop: 'auto',
      marginBottom: '20px',
    },
    mobile: {
      width: '90%',
      marginTop: 'auto',
      marginBottom: '16px',
    },
  },
};

const brandCardSkeletonDefaultPropSx = {};

const BrandCardSkeleton = ({ sx = brandCardSkeletonDefaultPropSx }) => {
  const isMobile = useResponsive('down', 'sm');
  const screenType = isMobile ? 'mobile' : 'web';

  return (
    <Card
      sx={{
        ...commonStyles.root[screenType],
        ...sx,
      }}
      data-testid="brand-card-skeleton"
    >
      <Skeleton
        data-testid="brand-card-image-skeleton"
        variant="rectangular"
        sx={{
          height: isMobile ? 172 : 236,
          width: '100%',
        }}
      />

      <Stack sx={commonStyles.brandInfo[screenType]}>
        <Skeleton data-testid="brand-card-name-skeleton" variant="text" width="60%" height={24} />

        <Stack spacing={1} alignItems="center">
          <Skeleton data-testid="brand-card-details-skeleton" variant="text" width="40%" height={18} />

          <Skeleton
            data-testid="brand-card-button-skeleton"
            variant="rectangular"
            sx={{
              ...commonStyles.followButton[screenType],
              height: 23,
              borderRadius: '4px',
            }}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

BrandCardSkeleton.propTypes = {
  sx: PropTypes.shape({}),
};

export default BrandCardSkeleton;
