import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';

const styles = {
  root: {
    width: 146,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  brandArea: {
    marginTop: '-0.25rem',
    marginBottom: '-0.25rem',
  },
  actions: {
    display: 'flex',
    marginTop: '-1rem',
    marginLeft: '-0.5rem',
    marginRight: '-0.5rem',
    marginBottom: '-1.5rem',
    justifyContent: 'space-between',
    '@media (min-width: 1200px) and (max-width: 1367px)': {
      whiteSpace: 'nowrap',
    },
    overflow: 'hidden',
  },
};

const CardTitle = styled(CardContent)(() => ({
  WebkitLineClamp: 2 /* number of lines to show */,
  overflow: 'hidden',
  display: 'WebkitBox',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
}));

const productCardSkeletonDefaultPropSx = {};

const ProductCardSkeleton = ({ isAuthenticated = false, sx = productCardSkeletonDefaultPropSx }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        ...sx,
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      }}
    >
      {isAuthenticated ? (
        <>
          <Skeleton
            variant="rectangular"
            width="100%"
            sx={{
              [theme.breakpoints.up('sm')]: {
                height: '314px',
              },
              [theme.breakpoints.down('sm')]: {
                height: '44vw',
              },
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              [theme.breakpoints.up('sm')]: {
                mx: 1,
                height: '50px',
              },
              [theme.breakpoints.down('sm')]: {
                mx: 0.5,
                height: 24,
              },
            }}
          />
          <CardHeader
            sx={{
              ...styles.brandArea,
              [theme.breakpoints.down('sm')]: {
                padding: 0.5,
              },
            }}
            avatar={
              <Skeleton
                animation="wave"
                variant="circular"
                sx={{
                  [theme.breakpoints.up('sm')]: {
                    height: 50,
                    width: 50,
                    mb: 1,
                  },
                  [theme.breakpoints.down('sm')]: {
                    height: 32,
                    width: 32,
                  },
                }}
              />
            }
            title={
              <Skeleton
                animation="wave"
                variant="text"
                sx={{ [theme.breakpoints.up('sm')]: { width: '100%', height: 30 } }}
              />
            }
            subheader={
              <Skeleton
                animation="wave"
                variant="text"
                sx={{
                  width: '40%',
                  [theme.breakpoints.up('sm')]: { mb: 2, height: 16 },
                  [theme.breakpoints.down('sm')]: {
                    height: 14,
                  },
                }}
              />
            }
          />
          <CardContent
            sx={{
              ...styles.actions,
              [theme.breakpoints.down('sm')]: {
                m: 0,
                p: 0.5,
                flexDirection: 'column',
                '&:last-child': {
                  pb: 0.5,
                },
              },
            }}
          >
            <CardContent
              sx={{
                [theme.breakpoints.down('sm')]: {
                  p: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                },
              }}
            >
              <Skeleton
                animation="wave"
                variant="text"
                sx={{
                  [theme.breakpoints.up('sm')]: { marginTop: '-1rem', height: 20, width: 60 },
                  [theme.breakpoints.down('sm')]: {
                    width: '5ch',
                  },
                }}
              />
              <Skeleton
                animation="wave"
                variant="text"
                sx={{
                  [theme.breakpoints.up('sm')]: {
                    width: 30,
                    height: 11,
                  },
                  [theme.breakpoints.down('sm')]: {
                    width: '4ch',
                  },
                }}
              />
            </CardContent>
            <Skeleton
              variant="rectangular"
              sx={{
                height: 36,
                [theme.breakpoints.up('sm')]: {
                  width: 120,
                },
                [theme.breakpoints.down('sm')]: {
                  mt: 0.5,
                  height: 32,
                  width: '100%',
                  borderRadius: '10px',
                },
              }}
            />
          </CardContent>
        </>
      ) : (
        <>
          <Skeleton variant="rectangular" width="100%" height="314px" />
          <CardTitle>
            <Skeleton variant="text" height="30px" sx={{ mx: 0.5, mt: -0.75 }} />
            <Skeleton variant="text" height="30px" sx={{ mx: 0.5 }} />
          </CardTitle>
          <CardContent>
            <Skeleton animation="wave" bgvariant="rectangular" height={60} width={205} sx={{ mt: -0.5, mx: 'auto' }} />
          </CardContent>
        </>
      )}
    </Card>
  );
};

ProductCardSkeleton.propTypes = {
  isAuthenticated: PropTypes.bool,
  sx: PropTypes.shape({}),
};

export default ProductCardSkeleton;
